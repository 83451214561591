import React from 'react';
import Typography from '@mui/material/Typography';
import RegisterSuccess from './RegisterSuccess';
import s from './FormSuccess.module.scss';

type FormSuccessProps = {
  title: string;
  subTitle: string;
};

const FormSuccess = ({ title, subTitle }: FormSuccessProps) => (
  <div className={s.container}>
    <RegisterSuccess />
    <Typography variant="h5" align="center" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h6" align="center" gutterBottom>
      {subTitle}
    </Typography>
  </div>
);

export default FormSuccess;
