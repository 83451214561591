import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '../../resources/icons/LogoutIcon';
import Logo from '../../containers/Logo/Logo';
import { HeaderProps } from '../../containers/Header/Header';
import s from './HeaderDesktop.module.scss';
import GetTransferDialog from '../GetTransferDialog/GetTransferDialog';
import Balance from '../../containers/Balance/Balance';

const HeaderDesktop = ({
  showWarning,
  loggedIn,
  userEmail,
  onLogout,
}: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={cx(s.container, showWarning && s.offset)}>
      <Link to="/">
        <Logo />
      </Link>
      {!loggedIn && (
        <div className={s.buttons}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to="/register"
          >
            {t('registration')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            component={Link}
            to="/login"
          >
            {t('login')}
          </Button>
        </div>
      )}
      {loggedIn && (
        <div className={s.authInfo}>
          <Balance />
          <GetTransferDialog />
          <Typography variant="body1">{userEmail}</Typography>
          <LogoutIcon
            onClick={() => onLogout()}
            style={{ cursor: 'pointer' }}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderDesktop;
