import robotApi from '../../app/api/robotApi';
import downloadDocument from '../../utilities/downloadDocument/downloadDocument';
import {
  Broker,
  Account,
  AccountData,
  AccountsRequest,
  AddAccountRequest,
  Currency,
  RobotType,
  UpdateAccountRequest,
  TerminalType,
  AccountsType,
  RobotPurchaseRequest,
  RobotPrice,
  RenewSubRequest,
  PriceInfo,
} from './types';

const robotApiSlice = robotApi.injectEndpoints({
  endpoints: (build) => ({
    getBrokers: build.query<Broker[], void>({
      query: () => ({
        url: '/api/robot/brokers',
        method: 'GET',
      }),
      transformResponse: (response: { data: Broker[] }) => {
        return response.data;
      },
    }),
    getAccounts: build.query<Account[], AccountsRequest>({
      query: (params) => ({
        url: '/api/robot/accounts',
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: Account[] }) => {
        return response.data;
      },
      providesTags: ['Accounts'],
    }),
    addAccount: build.mutation<{ id: number }, AddAccountRequest>({
      query: (body) => ({
        url: '/api/robot/accounts',
        method: 'POST',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(robotApi.util.invalidateTags(['Accounts']));
        }, 5000);
      },
    }),
    updateAccount: build.mutation<void, UpdateAccountRequest>({
      query: ({ id, ...body }) => ({
        url: `/api/robot/accounts/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Accounts'],
    }),
    deleteAccount: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `/api/robot/accounts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Accounts'],
    }),
    getCurrencies: build.query<Currency[], void>({
      query: () => ({
        url: '/api/robot/currencies',
        method: 'GET',
      }),
      transformResponse: (response: { data: Currency[] }) => {
        return response.data;
      },
    }),
    getAccount: build.query<AccountData, number>({
      query: (id) => ({
        url: `/api/robot/accounts/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: AccountData }) => {
        return response.data;
      },
      providesTags: ['Accounts'],
    }),
    buyRobot: build.mutation<{ id: number }, RobotPurchaseRequest>({
      query: ({ id, ...body }) => ({
        url: `/api/robot/accounts/${id}/robots`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(robotApi.util.invalidateTags(['Accounts', 'Balance']));
        }, 5000);
      },
    }),
    renewRobotSubscription: build.mutation<{ id: number }, RenewSubRequest>({
      query: ({ id, period }) => ({
        url: `/api/robot/accounts/${id}/subscriptions`,
        method: 'POST',
        body: {
          period,
        },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(robotApi.util.invalidateTags(['Accounts', 'Balance']));
        }, 5000);
      },
    }),

    getRobotsTypes: build.query<RobotType[], void>({
      query: () => ({
        url: '/api/robot/robots/types',
        method: 'GET',
      }),
      transformResponse: (response: { data: RobotType[] }) => {
        return response.data;
      },
    }),
    getRobotPrices: build.query<RobotPrice, { robotTypeId?: number } | void>({
      query: (params) => ({
        url: '/api/robot/prices',
        method: 'GET',
        params: params || {},
      }),
      transformResponse: (response: { data: RobotPrice }) => {
        return response.data;
      },
    }),
    getPriceInfo: build.query<PriceInfo, { id: number; period?: number }>({
      query: ({ id, period }) => ({
        url: `/api/robot/accounts/${id}/price-info`,
        method: 'GET',
        params: {
          id,
          period,
        },
      }),
    }),
    getAccountsTypes: build.query<AccountsType[], void>({
      query: () => ({
        url: '/api/robot/accounts/types',
        method: 'GET',
      }),
      transformResponse: (response: { data: AccountsType[] }) => {
        return response.data;
      },
    }),
    getTerminalTypes: build.query<TerminalType[], void>({
      query: () => ({
        url: '/api/robot/terminal-types',
        method: 'GET',
      }),
      transformResponse: (response: { data: TerminalType[] }) => {
        return response.data;
      },
    }),
    downloadRobot: build.query<any, { accountId: number }>({
      query: ({ accountId }) => ({
        url: `/api/robot/robots/${accountId}/download`,
        method: 'GET',
        responseHandler: (response) => downloadDocument(response),
      }),
    }),
  }),
});

export const {
  useGetBrokersQuery,
  useGetAccountsQuery,
  useAddAccountMutation,
  useGetCurrenciesQuery,
  useGetRobotsTypesQuery,
  useGetRobotPricesQuery,
  useGetPriceInfoQuery,
  useUpdateAccountMutation,
  useGetAccountQuery,
  useDeleteAccountMutation,
  useGetTerminalTypesQuery,
  useGetAccountsTypesQuery,
  useBuyRobotMutation,
  useRenewRobotSubscriptionMutation,
  useLazyDownloadRobotQuery,
} = robotApiSlice;
