import * as yup from 'yup';
import i18n from '../../locale/i18n';

const schema = () =>
  yup.object({
    password: yup
      .string()
      .min(6, i18n.t('password_valid_min', { value: 6 }) || undefined)
      .max(50, i18n.t('password_valid_max', { value: 50 }) || undefined)
      .matches(
        /^[0-9A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
        i18n.t('non_valid_symbols', { symbols: '0-9, A-Z, a-z' }) || undefined
      )
      .required(i18n.t('required_field') || undefined),
  });

export default schema;
