import { useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import withVerify from '../withVerify/withVerify';
import { FieldRenderProps } from 'react-final-form';

const FormSelect = ({
  input: { name, onChange, value, ...restInput },
  meta,
  label,
  formControlProps,
  hideError,
  ...rest
}: FieldRenderProps<Nullable<string | number>>) => {
  const labelRef = useRef(null);
  const showError = meta?.error && meta?.touched;
  const id = `select-id-${name}`;

  return (
    <FormControl
      fullWidth
      error={showError}
      variant="outlined"
      style={{ marginTop: 16, marginBottom: 8 }}
    >
      <InputLabel htmlFor={name} ref={labelRef} id={id}>
        {label}
      </InputLabel>

      <Select
        label={label}
        id={id}
        labelId={id}
        name={name}
        onChange={onChange}
        value={value}
        margin="dense"
        {...restInput}
        {...rest}
      />
      {showError && !hideError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withVerify(FormSelect);
