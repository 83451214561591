import React from 'react';
import cx from 'classnames';
import { Box, CircularProgress } from '@mui/material';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useGetStatusQuery } from '../../features/auth/authApiSlice';
import Robots from '../Robots/Robots';
import LeftMenu from '../LeftMenu/LeftMenu';
import Bonuses from '../Bonuses/Bonuses';
import Settings from '../Settings/Settings';
import History from '../History/History';

import s from './Main.module.scss';

const Main = () => {
  const navigate = useNavigate();
  const { data: status, isLoading } = useGetStatusQuery();

  if (isLoading) {
    return (
      <Box sx={{ m: '20% auto' }}>
        <CircularProgress size={64} />
      </Box>
    );
  }

  if (!status?.loggedIn) {
    navigate('/login');
    return null;
  }

  return (
    <div className={cx(s.container, s.extendedGrid)}>
      <div className={s.menu}>
        <LeftMenu />
      </div>
      <Routes>
        <Route path="/robots/*" element={<Robots />} />
        <Route path="/bonuses" element={<Bonuses />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/history/*" element={<History />} />
        <Route path="/*" element={<Navigate to="/robots" />} />
      </Routes>
    </div>
  );
};

export default Main;
