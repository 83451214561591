import { useState } from 'react';
import cx from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography, Collapse } from '@mui/material';
import { Referral } from '../../../features/bonuses/types';
import { TICKER } from '../Stats/Stats';
import s from './ReferralItem.module.scss';

interface ReferralItemProps extends Referral {
  displayLeaderCol?: boolean;
}

const ReferralItem = (props: ReferralItemProps) => {
  const {
    displayLeaderCol,
    level,
    firstName,
    lastName,
    bonus,
    leaderBonus,
    referrals,
  } = props;
  const marginLeft = window.innerWidth > 992 && level ? `${30 * level}px` : 0;

  const [expanded, setExpanded] = useState(false);
  const noReferrals = !referrals?.length;
  const switchExpand = () => {
    if (!noReferrals) {
      setExpanded(!expanded);
    }
  };

  return (
    <>
      <div className={cx(s.col, s.first)} style={{ marginLeft }}>
        <Typography variant="h6" className={s.title}>
          {expanded ? (
            <RemoveIcon
              className={s.removeIcon}
              color="primary"
              onClick={switchExpand}
            />
          ) : (
            <AddIcon
              className={noReferrals ? '' : s.addIcon}
              color={!noReferrals ? 'primary' : 'disabled'}
              onClick={switchExpand}
            />
          )}
          {firstName} {lastName}
        </Typography>
      </div>
      <div className={s.col}>
        <Typography
          variant="h6"
          color={bonus > 0 ? 'default' : 'textSecondary'}
        >
          {bonus} {TICKER}
        </Typography>
      </div>
      <div className={s.col}>
        {displayLeaderCol && (
          <Typography
            variant="h6"
            color={leaderBonus > 0 ? 'default' : 'textSecondary'}
          >
            {leaderBonus} {TICKER}
          </Typography>
        )}
      </div>

      {referrals?.length > 0 && (
        <>
          <Collapse
            in={expanded}
            className={[s.child, !displayLeaderCol ? s.two_columns : ''].join(
              ' '
            )}
          >
            {referrals.map((i, index) => (
              <ReferralItem
                key={`${i.firstName}_${i.lastName}_${index}`}
                displayLeaderCol={displayLeaderCol}
                {...i}
              />
            ))}
          </Collapse>
        </>
      )}
    </>
  );
};

export default ReferralItem;
