import React from 'react';
import { SvgIcon } from '@mui/material';

const DashboardIcon = () => {
  return (
    <SvgIcon viewBox="0 0 18 18">
      <path d="M17.3333.666667V17.3333H.666667V.666667H17.3333zM18 0H0v18h18V0z" />
      <path d="M6.33335 0h-.66666v18h.66666V0z" />
      <path d="M18 5.66666H6v.66666h12v-.66666zM18 11.6667H6v.6666h12v-.6666z" />
    </SvgIcon>
  );
};

export default DashboardIcon;
