import React, { useState, useEffect } from 'react';
import { Typography, DialogContent, Button, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDeleteAccountMutation } from '../../../../features/robot/robotApiSlice';
import { useEnqueueSnackbarRemoteData } from '../../../../utilities/snackbarEvents';
import s from '../BuyRobotFormDialog/BuyRobotFormDialog.module.scss';
import { Account, AccountStatuses } from '../../../../features/robot/types';

interface DeleteFormDialogProps {
  account: Account;
}

const DeleteFormDialog = ({ account }: DeleteFormDialogProps) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [deleteAccount, { isLoading, isError, error, isSuccess }] =
    useDeleteAccountMutation();

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  useEffect(() => {
    if (isLoading) {
      setOpen(false);
    }
    if (isLoading === false) {
      enqueueSnackbarRemoteData({
        isError,
        error,
        successMessage: isSuccess ? t('transaction_success') : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const setModalState = (state: boolean) => () => setOpen(state);

  function isDisabled(account: Account): boolean {
    return account?.status.value === AccountStatuses.onVerification;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disabled={isDisabled(account)}
        onClick={setModalState(true)}
      >
        {t('delete')}
      </Button>
      <Dialog
        open={isOpen}
        onClose={setModalState(false)}
        classes={{ paper: s.dialogPaper }}
        PaperProps={{ elevation: 0 }}
      >
        <DialogContent>
          <div className={s.close}>
            <CloseIcon onClick={setModalState(false)} fontSize="small" />
          </div>
          <Typography variant="body2" color="textSecondary">
            {t('account')} {account?.accountNumber}
          </Typography>
          <Typography className={s.title} variant="h5">
            {t('confirm_account_delete')}
          </Typography>
          <div className={s.control}>
            <Button
              onClick={() => deleteAccount(account?.id)}
              variant="contained"
              color="primary"
              size="large"
              style={{ marginTop: 24 }}
            >
              {t('delete')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{ marginTop: 24 }}
              onClick={setModalState(false)}
            >
              {t('cancel')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteFormDialog;
