import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  typography: {
    fontFamily: 'SF Pro Text',
    fontSize: 12,
  },
  palette: {
    mode: 'light',
    divider: '#E4E4E4',
    background: {
      default: '#F6F6F6',
      paper: '#fff',
    },
    primary: {
      main: '#3F86F7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#23B816',
      contrastText: '#fff',
    },
    error: {
      main: '#f24773',
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: 14,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: 'transparent',
          '&:-webkit-autofill,:-webkit-autofill:active,:-webkit-autofill:focus,:-webkit-autofill:hover':
            {
              WebkitTextFillColor: '#000',
              WebkitTransitionDelay: '99999s',
            },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderColor: '#3F86F7',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          height: 32,
          textTransform: 'none',
          paddingTop: 4,
          paddingBottom: 4,
        },
        outlinedPrimary: {
          paddingTop: 3,
          paddingBottom: 3,
          borderWidth: 1,
          borderColor: '#3F86F7',
          backgroundColor: 'transparent',
          '&:hover': {
            borderWidth: 1,
            backgroundColor: '#3F86F7',
            color: '#fff',
          },
        },
        contained: {
          boxShadow: 'none',
          '&$disabled': {
            backgroundColor: '#e0e0e0',
            boxShadow: 'none !important',
          },
        },
        containedPrimary: {
          '&$sizeLarge': {
            boxShadow: '0px 0px 14px rgba(63, 134, 247, 0.5)',
          },
        },
        sizeLarge: {
          height: 42,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& $notchedOutline': {
            borderColor: '#3F86F7',
            '& $disabled': {
              borderColor: '#999 !important',
            },
          },
          '& $disabled': {
            color: '#999',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: '#999',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        fixed: {
          borderBottom: '1px solid #e0e0e0',
          overflowX: 'unset',
          paddingBottom: 4,
        },
        indicator: {
          bottom: -1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginRight: 28,
          '@media (min-width: 960px)': {
            minWidth: 'unset',
          },
        },
        // TODO
        // label: {
        //   fontSize: 14,
        // },
        // labelContainer: {
        //   padding: '4px 0 !important',
        // },
        textColorInherit: {
          opacity: 1,
          textAlign: 'left',
          minHeight: 16,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          '& > *': {
            color: '#fff',
          },
        },
      },
    },
  },
});

export default lightTheme;
