import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from '../../app/store';
import { Login } from '../../features/auth/types';
import { setCookie } from '../../utilities/cookie';

interface User {
  login: Login;
}

const initialState: User = {
  login: { accessToken: '', refreshToken: '' },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserTokens: (state, action: PayloadAction<Login>) => {
      state.login = action.payload;
    },
  },
});

const { setUserTokens } = userSlice.actions;

export const getTokens = (): Login => {
  const state = store.getState();
  return state.user.login;
};

export const dispatchSetUserTokens = (login: Login) => {
  if (process.env.NODE_ENV === 'development') {
    setCookie('refreshToken', login.refreshToken, {
      secure: true,
      'max-age': 86400,
    });
  }
  store.dispatch(setUserTokens(login));
};

export default userSlice.reducer;
