import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Tab } from '@mui/material';
import Profile from './Profile/Profile';
import ChangePassword from './ChangePassword/ChangePassword';
import TwoFA from './TFA/TFA';
import ReferralCode from '../ReferralCode/ReferralCode';
import TabMenu from '../../components/TabMenu/TabMenu';
import UnverifiedIcon from '../../resources/icons/UnverifiedIcon';
import { useEnqueueSnackbarRemoteData } from '../../utilities/snackbarEvents';
import s from './Settings.module.scss';

const Settings = () => {
  const { t } = useTranslation();
  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  // TODO
  const isProfileRequired = false;

  return (
    <div className={s.container}>
      <Typography variant="h4">{t('settings')}</Typography>
      <TabMenu defaultTab="/settings/profile">
        <Tab
          label={
            <div className={s.labelInfo}>
              <Typography variant="body1">{t('profile')}</Typography>
              {isProfileRequired && <UnverifiedIcon />}
            </div>
          }
          value="/settings/profile"
          disableRipple
        />
        <Tab
          label={t('change_password')}
          value="/settings/password"
          disableRipple
        />
        <Tab label={t('2fa')} value="/settings/2fa" disableRipple />
      </TabMenu>
      <div className={s.content}>
        <Routes>
          <Route path="/profile" element={<Profile />} />
          <Route path="/password" element={<ChangePassword />} />
          <Route path="/2fa" element={<TwoFA />} />
          <Route path="/" element={<Navigate to="/settings/profile" />} />
        </Routes>
        <ReferralCode />
      </div>
    </div>
  );
};

export default Settings;
