import React, { useState, useEffect, ReactNode, useMemo } from 'react';
import {
  Typography,
  DialogContent,
  Button,
  Dialog,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import schema from './schema';
import Select from '../../../../components/Select/Select';
import validate from '../../../../utilities/validate';
import Input from '../../../../components/Input/Input';
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';
import { useEnqueueSnackbarRemoteData } from '../../../../utilities/snackbarEvents';
import {
  useAddAccountMutation,
  useGetAccountQuery,
  useGetBrokersQuery,
  useGetCurrenciesQuery,
  useUpdateAccountMutation,
  useGetTerminalTypesQuery,
  useGetAccountsTypesQuery,
} from '../../../../features/robot/robotApiSlice';
import {
  AddAccountRequest,
  Broker,
  Currencies,
  Currency,
  TerminalTypes,
} from '../../../../features/robot/types';
import { useGetUserInfoQuery } from '../../../../features/auth/authApiSlice';
import removeSpaces from '../../../../utilities/removeSpaces/removeSpaces';
import { AccountTypes } from '../../../../features/robot/types';
import { isNaN } from 'lodash';
import s from './AddAccountFormDialog.module.scss';
import trim from '../../../../utilities/trim/trim';

type AddAccountFormDialogProps = {
  dialogOpeningComponent: (onClick: () => void) => ReactNode;
  disabled?: boolean;
  brokerId?: number;
  accountId?: number;
};

const AddAccountFormDialog = ({
  dialogOpeningComponent,
  disabled,
  brokerId,
  accountId,
}: AddAccountFormDialogProps) => {
  const { t } = useTranslation();
  const [valuesAreChanged, setValuesChanged] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { data: brokers } = useGetBrokersQuery();
  const { data: currencies } = useGetCurrenciesQuery();
  const { data: accountsTypes } = useGetAccountsTypesQuery();
  const { data: terminalTypes } = useGetTerminalTypesQuery();
  const { data: userInfo } = useGetUserInfoQuery();
  const { data: account, isLoading: gettingAccountInfo } = useGetAccountQuery(
    Number(accountId),
    {
      skip: accountId == null || !isOpen,
    }
  );

  const validationSchema = useMemo(() => schema, []);

  const [addAccount, { isSuccess, isError, error, isLoading }] =
    useAddAccountMutation();
  const [
    updateAccount,
    {
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      isLoading: isLoadingUpdate,
    },
  ] = useUpdateAccountMutation();

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  useEffect(() => {
    if (isLoading === false) {
      enqueueSnackbarRemoteData({
        isError,
        error,
        successMessage: isSuccess ? t('verification_request_sent') : '',
      });

      if (isSuccess) {
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isLoadingUpdate === false) {
      enqueueSnackbarRemoteData({
        isError: isErrorUpdate,
        error: 'NotUpdatedTitle',
        successMessage: isSuccessUpdate ? t('verification_request_sent') : '',
      });
    }
    if (isSuccessUpdate) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdate]);

  const setModalState = (state: boolean) => () => setOpen(state);

  const onSubmit = (
    values: AddAccountRequest & { accountNumber: string | number }
  ) => {
    const formattedValues = {
      ...values,
      phone: removeSpaces(values?.phone),
      accountNumber: Number(values.accountNumber),
    };

    if (brokerId) {
      addAccount(formattedValues);
    } else if (accountId) {
      updateAccount({ ...formattedValues, id: accountId });
    }
  };

  function normalizeNumber(value: string) {
    if (!value) return null;
    const onlyNums = Number(value);

    if (isNaN(onlyNums)) {
      return value.replace(/[^\d]/g, '');
    } else {
      return onlyNums;
    }
  }

  return (
    <>
      {dialogOpeningComponent(setModalState(!disabled))}
      <Dialog
        open={isOpen}
        onClose={setModalState(false)}
        classes={{ paper: s.dialogPaper }}
        PaperProps={{ elevation: 0 }}
      >
        {!gettingAccountInfo && (
          <DialogContent>
            <div className={s.close}>
              <CloseIcon onClick={setModalState(false)} fontSize="small" />
            </div>
            <Typography className={s.title} variant="h3">
              {brokerId && t('add_account')}
              {accountId && t('edit_account')}
            </Typography>
            <Typography variant="body1">{t('add_account_subtitle')}</Typography>
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                return validate(values, validationSchema());
              }}
            >
              {({ handleSubmit, valid }) => (
                <form onSubmit={handleSubmit} className={s.form}>
                  <Field
                    showError={true}
                    name="firstName"
                    component={Input}
                    label={t('first_name')}
                    variant="outlined"
                    defaultValue={account?.firstName || userInfo?.firstName}
                    required
                    autoFocus
                    formatOnBlur
                    format={(value) => trim(value)}
                  />
                  <Field
                    showError={true}
                    name="lastName"
                    component={Input}
                    label={t('last_name')}
                    variant="outlined"
                    defaultValue={account?.lastName || userInfo?.lastName}
                    required
                    formatOnBlur
                    format={(value) => trim(value)}
                  />
                  <Field
                    name="brokerId"
                    component={Select}
                    label={`${t('broker')} *`}
                    hideError
                    required
                    className={s.selectField}
                    defaultValue={
                      account?.broker.id ||
                      brokers?.find(({ id }) => id === brokerId)?.id
                    }
                    disabled
                  >
                    {(brokers || []).map(({ id, name }: Broker) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="email"
                    component={Input}
                    label="Email"
                    variant="outlined"
                    defaultValue={account?.email || userInfo?.email}
                    required
                    showError
                  />
                  <Field
                    name="phone"
                    component={PhoneInput}
                    label={t('phone')}
                    defaultValue={account?.phone || userInfo?.phone}
                    validate={(value) => {
                      return isValidPhoneNumber(value || '')
                        ? undefined
                        : 'invalid_phone';
                    }}
                    required
                    showError
                  />
                  <Field
                    name="accountNumber"
                    component={Input}
                    label={t('account_mt5')}
                    variant="outlined"
                    parse={normalizeNumber}
                    defaultValue={Number(account?.accountNumber) || null}
                    required
                  />
                  <Field
                    // initialValue={currencies?.find((curr) => curr.id === 1)?.id} //USD
                    name="currencyId"
                    component={Select}
                    label={`${t('currency')} *`}
                    hideError
                    defaultValue={
                      account?.currency.id ||
                      currencies?.find((curr) => curr.name === Currencies.usd)
                        ?.id
                    }
                    required
                    className={s.selectField}
                  >
                    {(currencies || []).map((e: Currency) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                    name="accountTypeId"
                    component={Select}
                    label={`${t('account_type')} *`}
                    hideError
                    defaultValue={
                      account?.accountType.id ||
                      accountsTypes?.find(
                        (acc) => acc.name === AccountTypes.proCent
                      )?.id
                    }
                    required
                    className={s.selectField}
                  >
                    {(accountsTypes || []).map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="terminalTypeId"
                    component={Select}
                    label={`${t('terminal_type')} *`}
                    hideError
                    defaultValue={
                      account?.terminalType.id ||
                      terminalTypes?.find(
                        (type) => type.name === TerminalTypes['mt5']
                      )?.id
                    }
                    required
                    className={s.selectField}
                  >
                    {(terminalTypes || []).map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={s.control}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ marginTop: 24 }}
                      type="submit"
                      disabled={isLoading || !valuesAreChanged || !valid}
                    >
                      {t('verify')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      style={{ marginTop: 24 }}
                      onClick={setModalState(false)}
                    >
                      {t('cancel')}
                    </Button>
                  </div>
                  <FormSpy
                    subscription={{
                      dirty: true,
                      modified: true,
                    }}
                  >
                    {({ modified }) => {
                      // Handle changes in the form state here

                      setValuesChanged(
                        Object.values(
                          modified as { [s: string]: boolean }
                        ).some((val) => val === true)
                      );

                      return null; // The FormSpy component doesn't render anything
                    }}
                  </FormSpy>
                </form>
              )}
            </Form>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default AddAccountFormDialog;
