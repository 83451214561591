import React, { useEffect } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import AutoSave from '../../utilities/AutoSave';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { HistoryRequest } from '../../features/withdrawal/types';
import getDateRange from '../../utilities/getDateRange/getDateRange';
import toFormatISO from '../../utilities/toFormatISO/toFormatISO';
import { DateFilter } from '../../types';

interface HistoryFilterFormProps {
  getHistory: (data: HistoryRequest) => void;
}

const HistoryFilterForm = ({ getHistory }: HistoryFilterFormProps) => {
  const initialValues = { dates: getDateRange(1) };

  const getData = (values: DateFilter) => {
    const { startDate, endDate } = values.dates;
    const from = toFormatISO(startDate);
    const to = toFormatISO(endDate);
    if (from && to) {
      getHistory({
        From: from,
        To: to,
        Currency: 'USDT',
        PageIndex: 0,
        PageSize: 1000,
      });
    }
  };

  useEffect(() => {
    getData(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: FormRenderProps['values']) => {
    if (values.dates.key === 'selection') {
      getData(values as DateFilter);
    }
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <>
          <AutoSave save={onSubmit} debounce={500} />
          <Field name="dates" component={DateRangePicker} />
        </>
      )}
    </Form>
  );
};

export default HistoryFilterForm;
