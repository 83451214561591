import React from 'react';
import { SvgIcon } from '@mui/material';

const HistoryIcon = () => {
  return (
    <SvgIcon viewBox="0 0 18 18">
      <path d="M9 .666667C13.6.666667 17.3333 4.4 17.3333 9S13.6 17.3333 9 17.3333.666667 13.6.666667 9 4.4.666667 9 .666667zM9 0C4 0 0 4 0 9s4 9 9 9 9-4 9-9-4-9-9-9z" />
      <path d="M9.33335 3h-.66666v6h.66666V3z" />
      <path d="M13.3334 8.66667H8.66669v.66667h4.66671v-.66667z" />
    </SvgIcon>
  );
};

export default HistoryIcon;
