import robotApi from '../../app/api/robotApi';
import { Balance, TransferTransaction, History } from './types';
import { HistoryRequest } from '../withdrawal/types';

const depositoryApiSlice = robotApi.injectEndpoints({
  endpoints: (build) => ({
    getBalance: build.query<Balance, void>({
      query: () => ({
        url: '/api/usd/balance',
        method: 'GET',
      }),
      transformResponse: (response: { data: Balance }) => {
        return response.data;
      },
      providesTags: ['Balance'],
    }),
    createTransferTransaction: build.mutation<
      { data: number },
      TransferTransaction
    >({
      query: (body) => ({
        url: '/api/usdt/depository/create-transfer-transaction',
        method: 'POST',
        body,
      }),
    }),
    getAccountAddress: build.query<string, void>({
      query: () => ({
        url: '/address',
        method: 'GET',
      }),
    }),
    getHistory: build.query<History, HistoryRequest>({
      query: (params) => ({
        url: '/api/usd/history',
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: History }) => {
        return response.data;
      },
    }),
    getIncome: build.query<History, HistoryRequest>({
      query: (params) => ({
        url: '/api/usd/income',
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: History }) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetBalanceQuery,
  useCreateTransferTransactionMutation,
  useGetAccountAddressQuery,
  useLazyGetHistoryQuery,
  useLazyGetIncomeQuery,
  useGetIncomeQuery,
} = depositoryApiSlice;
