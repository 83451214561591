import * as React from 'react';
import { Checkbox, FormControlLabel, FormControl, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { FieldRenderProps } from 'react-final-form';

const styles = (theme: Theme) => ({
  labelError: {
    color: theme.palette.error.main,
  },
  MuiButtonBase: {
    color: theme.palette.error.main,
  },
});

const CheckBox = ({
  theme,
  classes,
  input: { name, onChange, ...restInput },
  meta,
  label,
  ...rest
}: FieldRenderProps<string>) => {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...rest}
            name={name}
            inputProps={restInput}
            onChange={onChange}
            sx={{
              '& path': {
                color:
                  meta.touched && meta.error
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
              },
            }}
          />
        }
        label={<>{label}</>}
        classes={{ label: meta.touched && meta.error && classes.labelError }}
      />
    </FormControl>
  );
};

export default withStyles(styles, { withTheme: true })(CheckBox);
