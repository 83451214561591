import React from 'react';
import { Paper, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetBalanceQuery } from '../../../features/depository/depositoryApiSlice';
import s from './WithdrawBonuses.module.scss';
import WithdrawDialog from '../Dialogs/WithdrawDialog/WithdrawDialog';
import { useAppSelector } from '../../../hooks/redux/hooks';
import { AppState } from '../../../app/store';

const WithdrawBonuses = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetBalanceQuery();
  const reduxBalance = useAppSelector((state: AppState) => state.balance);

  return (
    <Paper className={s.container} elevation={0}>
      <div className={s.content}>
        <Typography variant="h5">{t('balance')}</Typography>
        {isLoading ? (
          <CircularProgress size={16} />
        ) : (
          <Typography variant="h4">
            {reduxBalance.balance.amount || data?.amount}
          </Typography>
        )}

        <Typography variant="h5">USD</Typography>
      </div>
      <WithdrawDialog />
    </Paper>
  );
};

export default WithdrawBonuses;
