import React, { useState, useEffect, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Collapse, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import UnverifiedIcon from '../../resources/icons/UnverifiedIcon';
import s from './LeftMenu.module.scss';

interface LeftMenuItemProps {
  icon: ReactElement;
  title: string;
  to: string;
  children?: ReactElement;
  unverified?: boolean;
  defaultExpanded?: boolean;
  isNew?: boolean;
  showItem?: boolean;
}

const LeftMenuItem = ({
  icon,
  title,
  to,
  children,
  unverified = false,
  defaultExpanded = false,
  isNew = false,
  showItem = false,
  ...props
}: LeftMenuItemProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const switchExpand = () => setExpanded(!expanded);

  useEffect(() => {
    if (children) {
      const childrenArr = [];
      if (Array.isArray(children) && children.length) {
        children.forEach((i) => {
          childrenArr.push(i.props.to);
        });
      } else {
        childrenArr.push(children.props.to);
      }
      if (childrenArr.includes(location.pathname)) {
        setExpanded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const optionTheme = theme.palette.mode;

  return (
    <div>
      {!children && (
        <NavLink
          to={to}
          className={({ isActive }) =>
            cx(s.item, isActive && s.active, s[optionTheme])
          }
          {...props}
        >
          {icon || <span />}
          <Typography variant="body1">{title}</Typography>
          {unverified && <UnverifiedIcon />}
          {isNew && (
            <div className={s.new}>
              <Typography variant="body2">NEW</Typography>
            </div>
          )}
        </NavLink>
      )}
      {children && (
        <>
          <NavLink
            to={to}
            className={({ isActive }) =>
              cx(s.item, isActive && s.active, s[optionTheme])
            }
            {...props}
            onClick={showItem ? switchExpand : () => {}}
          >
            {icon || <span />}
            <Typography variant="body1">{title}</Typography>
            {isNew ? (
              <div className={s.new}>
                <Typography variant="body2">NEW</Typography>
              </div>
            ) : (
              <DownIcon
                className={cx(s.icon, expanded && s.rotate)}
                fontSize="small"
                onClick={switchExpand}
              />
            )}
          </NavLink>
          <Collapse in={expanded}>
            <div className={s.sub}>{children}</div>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default LeftMenuItem;
