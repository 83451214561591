import { formatISO } from 'date-fns';

const toFormatISO = (date: unknown): string | null => {
  try {
    if (date instanceof Date) {
      return formatISO(date);
    }
    return null;
  } catch {
    return null;
  }
};

export default toFormatISO;
