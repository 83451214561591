import { t } from 'i18next';
import * as yup from 'yup';

const schema = () => {
  return yup.object({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/g, t('letters_only_latin') || undefined)
      .required(t('required_field') || undefined),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/g, t('letters_only_latin') || undefined)
      .required(t('required_field') || undefined),
    email: yup
      .string()
      .email(t('email_not_valid') || ' ')
      .required(t('required_field') || undefined),
    accountNumber: yup.string().required(),
    phone: yup.string().required(),
    currencyId: yup.number().required(),
    accountTypeId: yup.number().required(),
  });
};

export default schema;
