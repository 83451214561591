import { setIn } from 'final-form';
import * as yup from 'yup';

const validate = async (
  values: any,
  schema: yup.ObjectSchema<any>,
  context?: any
) => {
  try {
    await schema.validate(values, { abortEarly: false, context });
  } catch (e: any) {
    return (e.inner || []).reduce(
      (errors: object, error: { path: string; message: any }) => {
        return setIn(errors, error.path, error.message);
      },
      {}
    );
  }

  return null;
};

export default validate;
