import cashInApi from '../../app/api/cashInApi';
import { getAddressResponse, History } from './types';
import { HistoryRequest } from '../withdrawal/types';

const cashInApiSlice = cashInApi.injectEndpoints({
  endpoints: (build) => ({
    getAddress: build.query<getAddressResponse, void>({
      query: () => ({
        url: '/api/cash-in/address',
        method: 'GET',
      }),
      transformResponse: (response: { data: getAddressResponse }) => {
        return response.data;
      },
    }),
    getHistory: build.query<History, HistoryRequest>({
      query: (params) => ({
        url: '/api/cash-in/history',
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: History }) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetAddressQuery, useLazyGetHistoryQuery } = cashInApiSlice;
