import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountTableRow from './AccountTableRow/AccountTableRow';
import AccountTableHeader from './AccountTableRow/AccountTableHeader';
import s from './AccountTable.module.scss';
import { Account } from '../../../../features/robot/types';

interface AccountTableProps {
  accounts: Account[];
}

const AccountTable = ({ accounts }: AccountTableProps) => {
  const { t } = useTranslation();

  const header = {
    accountId: t('account'),
    status: t('status'),
    robot: t('robot'),
    ownership: t('ownership'),
    actions: t('actions'),
  };

  return (
    <div className={s.main}>
      <AccountTableHeader data={header} />
      {accounts.map((i) => (
        <AccountTableRow account={i} key={i.id} />
      ))}
    </div>
  );
};

export default AccountTable;
