import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../utilities/prepareHeaders';
import { baseQueryWithReauth } from './authApi';

// const ACCOUNT_ROBOT_API = window.config.ACCOUNT_ROBOT_API;
const query = fetchBaseQuery({
  prepareHeaders,
  baseUrl: '/',
  credentials: 'include',
});

export const robotApi = createApi({
  reducerPath: 'robot',
  baseQuery: baseQueryWithReauth(query),
  tagTypes: ['Accounts', 'Balance'],
  endpoints: () => ({}),
});

export default robotApi;
