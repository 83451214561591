import lightTheme from './lightTheme';
import darkTheme from './darkTheme';

const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';

const getTheme = (
  theme: typeof LIGHT_THEME | typeof DARK_THEME | undefined
) => {
  switch (theme) {
    case LIGHT_THEME:
      return lightTheme;
    default:
      return darkTheme;
  }
};

export { getTheme, LIGHT_THEME, DARK_THEME };
