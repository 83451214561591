import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TransactionItem from './TransactionItem/TransactionItem';
import HistoryFilterForm from './HistoryFilterForm';
import {
  useLazyGetHistoryQuery,
  useLazyGetIncomeQuery,
  useGetIncomeQuery,
} from '../../features/depository/depositoryApiSlice';
import TableHeader, { FieldNames } from './TransactionItem/TableHeader';
import s from './History.module.scss';

const HistoryBuy = () => {
  const { t } = useTranslation();
  const { data: dataIncomeForCheck, isSuccess: isSuccessDataIncomeForCheck } =
    useGetIncomeQuery({
      From: '',
      To: '',
      Currency: 'USD',
      PageIndex: 1,
      PageSize: 1,
    });
  const [
    getHistory,
    {
      data: dataHistory,
      isFetching: isFetchingHistory,
      isSuccess: isSuccessHistory,
    },
  ] = useLazyGetHistoryQuery();

  const [
    getIncome,
    {
      data: dataIncome,
      isFetching: isFetchingIncome,
      isSuccess: isSuccessIncome,
    },
  ] = useLazyGetIncomeQuery();

  if (!isSuccessDataIncomeForCheck) return null;

  const isIncomeData = dataIncomeForCheck.items.length > 0;

  const getData = isIncomeData ? getIncome : getHistory;
  const data = isIncomeData ? dataIncome : dataHistory;
  const isFetching = isIncomeData ? isFetchingIncome : isFetchingHistory;
  const isSuccess = isIncomeData ? isSuccessIncome : isSuccessHistory;

  return (
    <div className={s.container}>
      <div className={s.top}>
        <HistoryFilterForm getHistory={getData} />
        {isFetching && <CircularProgress />}
      </div>
      {data && (
        <div className={s.items}>
          {!isFetching && data.items?.length === 0 && (
            <Typography variant="body1">{t('records_not_found')}</Typography>
          )}
          {isSuccess && data.items?.length > 0 && (
            <>
              <TableHeader
                hide={[FieldNames.fee, FieldNames.address]}
                isIncomeData={isIncomeData}
              />
              {data.items &&
                data.items.map((i) => (
                  <TransactionItem
                    item={i}
                    key={i.timestamp}
                    isIncomeData={isIncomeData}
                  />
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HistoryBuy;
