import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  Box,
} from '@mui/material';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import numbro from 'numbro';
import validate from '../../../../utilities/validate';
import { useEnqueueSnackbarRemoteData } from '../../../../utilities/snackbarEvents';
import AutoSave from '../../../../utilities/AutoSave';
import schema from './schema';
import Input from '../../../../components/Input/Input';
import {
  Currency,
  EstimationFeeRequest,
  WithdrawRequest,
} from '../../../../features/withdrawal/types';
import {
  useWithdrawMutation,
  useGetEstimationFeeMutation,
} from '../../../../features/withdrawal/withdrawalApiSlice';
import getTypeLabel from '../../../../utilities/cryptoTypeLabel';
import CptAmountInput from '../../../../components/CptAmountInput/CptAmountInput';
import { useGetBalanceQuery } from '../../../../features/depository/depositoryApiSlice';
import { useGetMfaEnabledQuery } from '../../../../features/auth/authApiSlice';
import TFA from '../../../Settings/TFA/TFA';
import { FormApi } from 'final-form';
import s from './WithdrawDialog.module.scss';

type WithdrawDialogProps = {};

const WithdrawDialog = (props: WithdrawDialogProps) => {
  const [isOpen, setOpen] = useState(false);
  const [form, setForm] =
    useState<FormApi<WithdrawRequest, Partial<WithdrawRequest>>>();
  const { t } = useTranslation();
  const { data: dataAmount } = useGetBalanceQuery();
  const decimalsValue = 0;

  const { data: TFAData } = useGetMfaEnabledQuery();
  const [
    sendForm,
    { isSuccess, isLoading: isLoadingSendForm, isError, error },
  ] = useWithdrawMutation();
  const [
    estimationFeeRequest,
    { data: dataFee, isSuccess: isSuccessFee, isLoading: isLoadingFee, reset },
  ] = useGetEstimationFeeMutation();

  const currency: Currency = 'USD';
  const amount = dataAmount?.amount || 0;

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  const setModalState = (state: boolean) => () => {
    setOpen(state);
    reset();
  };

  useEffect(() => {
    if (isOpen) {
      enqueueSnackbarRemoteData({
        isError,
        successMessage: isSuccess ? t('transaction_success') : '',
        error,
      });

      if (isSuccess && form) {
        form.reset();
        setModalState(false)();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError, isSuccess, form]);

  const onSubmit = (
    values: WithdrawRequest,
    f: FormApi<WithdrawRequest, Partial<WithdrawRequest>>
  ) => {
    sendForm({ ...values, currency: 'USDT' });
    setForm(f);
  };

  const getFee = (value: FormRenderProps['values']) => {
    const {
      message,
      currency = 'USDT',
      amount,
      address,
    } = value as EstimationFeeRequest;
    estimationFeeRequest({ amount, currency, address, message });
  };

  const maxAmount = Number(amount) - Number(dataFee?.feeAmount || 0);

  const setMaxDecimals = (value: string) => {
    const decimals = value.split('.')[1];
    if (decimals && decimals.length > decimalsValue) {
      return `${value.split('.')[0]}.${decimals.substring(0, decimalsValue)}`;
    }
    return value;
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={setModalState(true)}
        // disabled
      >
        {t('send_withdraw')}
      </Button>
      <Dialog open={isOpen} onClose={setModalState(false)}>
        <DialogContent>
          <Form
            onSubmit={onSubmit}
            validate={(values) =>
              validate(values, schema, {
                maxPrice: maxAmount,
              })
            }
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <AutoSave
                  debounce={500}
                  save={getFee}
                  validFields={['address', 'amount']}
                />
                <Typography variant="body1">
                  {t('send_transfer_address', {
                    currency,
                    type: getTypeLabel(currency),
                  })}
                </Typography>
                <Field
                  name="address"
                  component={Input}
                  label={t('receiver_address')}
                  variant="outlined"
                />
                <Typography variant="body1" style={{ marginTop: 16 }}>
                  {t('send_transfer_amount', {
                    currency,
                    type: getTypeLabel(currency, true),
                  })}
                </Typography>
                <CptAmountInput
                  endAdornment={currency}
                  label={t('amount')}
                  helperText={`${numbro(amount).format({
                    thousandSeparated: true,
                    trimMantissa: true,
                    mantissa: 2,
                  })} ${currency} ${t('available_in_wallet')}`}
                  name="amount"
                  format={(c: string) =>
                    setMaxDecimals((c || '').toString().replace(',', '.'))
                  }
                />
                {TFAData?.enabled ? (
                  <Field
                    sx={{ mt: 1 }}
                    name="code2Fa"
                    component={Input}
                    label={t('2fa_code')}
                    margin="none"
                    variant="outlined"
                    fullWidth={false}
                  />
                ) : (
                  <>
                    <Typography variant="h6">{t('tfa_code')}</Typography>
                    <Typography variant="body1" mb="1rem">
                      {t('tfa_required')}
                    </Typography>
                    <TFA />
                  </>
                )}
                <div className={s.bottom}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={
                        !TFAData?.enabled || !isSuccessFee || isLoadingSendForm
                      }
                    >
                      {t('send_transfer')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={setModalState(false)}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                  {isSuccessFee && (
                    <Typography variant="body1">
                      {t('fee')}: {dataFee?.feeAmount} {dataFee?.currency}
                    </Typography>
                  )}
                  {isLoadingFee && (
                    <CircularProgress
                      color="primary"
                      size={16}
                      thickness={3}
                      style={{ marginLeft: 8, verticalAlign: 'center' }}
                    />
                  )}
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WithdrawDialog;
