import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '../../containers/Logo/Logo';
import MobileMenu from '../MobileMenu/MobileMenu';
import { HeaderProps } from '../../containers/Header/Header';
import s from './HeaderMobile.module.scss';

const HeaderMobile = (props: HeaderProps) => {
  return (
    <div className={cx(s.container, props.showWarning && s.offset)}>
      <MobileMenu {...props} />
      <div className={s.logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  );
};

export default HeaderMobile;
