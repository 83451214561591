import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TransactionItem from './TransactionItem/TransactionItem';
import HistoryFilterForm from './HistoryFilterForm';
import { useLazyGetHistoryQuery } from '../../features/cashIn/cashInApiSlice';
import TableHeader, { FieldNames } from './TransactionItem/TableHeader';
import s from './History.module.scss';

const HistoryDeposit = () => {
  const { t } = useTranslation();
  const [getHistory, { data, isFetching, isSuccess }] =
    useLazyGetHistoryQuery();

  return (
    <div className={s.container}>
      <div className={s.top}>
        <HistoryFilterForm getHistory={getHistory} />
        {isFetching && <CircularProgress />}
      </div>
      {data && (
        <div className={s.items}>
          {!isFetching && data.items?.length === 0 && (
            <Typography variant="body1">{t('records_not_found')}</Typography>
          )}
          {isSuccess && data.items?.length > 0 && (
            <>
              <TableHeader hide={[FieldNames.address]} />
              {data.items &&
                data.items.map((i) => (
                  <TransactionItem item={i} key={i.txHash} />
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HistoryDeposit;
