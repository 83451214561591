import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from '../../hooks/router/useQuery';
import { useVerifyEmailQuery } from '../../features/auth/authApiSlice';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { ErrorData } from '../../utilities/snackbarEvents';

const VerifyEmailContent = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const email = query.get('email') || '';
  const token = (query.get('token') || '').replace(/ /g, '+');

  const { isSuccess, isLoading, isError, error } = useVerifyEmailQuery(
    { email, token },
    { skip: !email || !token }
  );

  if (!email || !token) {
    return <Typography variant="h5">Link is not correct</Typography>;
  }

  if (isLoading) {
    if (isLoading) {
      return (
        <Box sx={{ m: '20% auto', textAlign: 'center' }}>
          <CircularProgress size={64} />
        </Box>
      );
    }
  }

  if (isSuccess) {
    return (
      <>
        <Typography variant="h5">{t('mobile_promo_dev_auth')}</Typography>
        <Link to="/login">{t('login')}</Link>
      </>
    );
  }

  if (isError) {
    const errorData = error as unknown as ErrorData;
    return (
      <Typography variant="h5" color="error">
        {errorData?.data?.title || 'The request failed'}
      </Typography>
    );
  }
  return null;
};

const VerifyEmail = () => {
  return (
    <Container>
      <VerifyEmailContent />
    </Container>
  );
};

export default VerifyEmail;
