import React, { ReactNode } from 'react';
import { Paper, Typography } from '@mui/material';
import s from './FormBlock.module.scss';

type FormBlockProps = {
  title: string;
  children: ReactNode;
};

const FormBlock = ({ title, children }: FormBlockProps) => {
  return (
    <div className={s.container}>
      <Typography variant="h6" gutterBottom className={s.title}>
        {title}
      </Typography>
      <Paper elevation={0} square className={s.content}>
        {children}
      </Paper>
    </div>
  );
};

export default FormBlock;
