import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../utilities/prepareHeaders';
import { baseQueryWithReauth } from './authApi';

const query = fetchBaseQuery({
  prepareHeaders,
  baseUrl: '/',
  credentials: 'include',
});

export const bonusesApi = createApi({
  reducerPath: 'bonuses',
  baseQuery: baseQueryWithReauth(query),
  tagTypes: [],
  endpoints: () => ({}),
});

export default bonusesApi;
