export interface Broker {
  id: number;
  name: string;
}

interface OwnershipType {
  id: number;
  value: OwnershipValues;
}

export interface RobotType {
  id: number;
  name: string;
}
export interface Robot {
  id: number;
  type: RobotType;
  finishTime?: string;
  ownershipType: OwnershipType;
  isDownloadable: boolean;
}

interface AccountStatus {
  id: 1 | 2 | 3;
  value: AccountStatuses;
}

export enum AccountStatuses {
  onVerification = 'OnVerification',
  verified = 'Verified',
  notVerified = 'NotVerified',
}

export enum OwnershipValues {
  permanent = 'Permanent',
  trial = 'Trial',
  subscription = 'Subscription',
  paused = 'Paused',
}

export interface Account {
  id: number;
  userId: number;
  accountNumber: number;
  status: AccountStatus;
  periodToPermanent: number;
  robot?: Robot;
  comment?: string;
}

export interface Currency {
  id: number;
  name: string;
}

export interface AccountType {
  id: number;
  name: string;
}
export interface AccountData extends Account {
  firstName: 'string';
  lastName: 'string';
  email: 'string';
  phone: 'string';
  currency: Currency;
  accountType: AccountType;
  terminalType: TerminalType;
  broker: Broker;
}

export interface AccountsRequest {
  brokerId: number;
  userId?: number;
}

export interface AddAccountRequest {
  firstName: string;
  lastName: string;
  brokerId: number;
  email: string;
  phone: string;
  accountNumber: number;
  currencyId: number;
  accountTypeId: number;
  terminalTypeId: number;
}

export interface UpdateAccountRequest extends AddAccountRequest {
  id: number;
}

export interface TerminalType {
  id: number;
  name: string;
}

export interface AccountsType {
  id: number;
  name: string;
}

export interface RobotPurchaseBody {
  robotTypeId: number;
  ownershipTypeId: PurchaseType;
  period?: number;
}

export interface RobotPurchaseRequest extends RobotPurchaseBody {
  id: number;
}

export interface RenewSubRequest {
  id: number;
  period: number;
}
export interface PriceInfo {
  period: number;
  permanentPrice: number;
  subscriptionPrice: number;
}

export interface RobotPrice {
  valueInUsd: number;
}

export enum PurchaseType {
  per = 'permanent',
  sub = 'subscription',
}

export enum AccountTypes {
  pro = 'Pro',
  proCent = 'ProCent',
}

export enum Currencies {
  usd = 'USD',
  usdt = 'USDT',
}

export enum TerminalTypes {
  mt5 = 'MT5',
  mt4 = 'MT4',
}
