import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Box, Button, CircularProgress } from '@mui/material';
import validate from '../../../utilities/validate';
import schema from './schema';
import Input from '../../../components/Input/Input';
import TfaConfirm from '../../../components/TFAConfirm/TFAConfirm';
import {
  useChangePasswordMutation,
  useGetMfaEnabledQuery,
} from '../../../features/auth/authApiSlice';
import { ChangePasswordFormValues } from '../../../features/auth/types';
import { useEnqueueSnackbarRemoteData } from '../../../utilities/snackbarEvents';
import FieldPassword from '../../../components/FieldPassword/FieldPassword';

const ChangePassword = () => {
  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();
  const { data: TFAData } = useGetMfaEnabledQuery();
  const { t } = useTranslation();
  const [
    changePassword,
    { isSuccess, isError, error, isLoading: changingPassword },
  ] = useChangePasswordMutation();

  const onSubmit = async ({
    tfa_code: otpCode,
    ...values
  }: ChangePasswordFormValues) => {
    await changePassword({ ...values, otpCode });
  };

  useEffect(() => {
    enqueueSnackbarRemoteData({
      isError,
      error,
      successMessage: isSuccess ? t('success_changes_saved') : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError, isSuccess]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={(values) => validate(values, schema())}
      >
        {({ handleSubmit, form }) => (
          <form>
            <FieldPassword
              disabled={changingPassword}
              name="oldPassword"
              component={Input}
              label={t('old_password')}
              type="password"
              variant="outlined"
              autoFocus
            />
            <FieldPassword
              disabled={changingPassword}
              name="newPassword"
              component={Input}
              label={t('password')}
              type="password"
              variant="outlined"
              showError
            />

            <Box mt="16px">
              <TfaConfirm />
              {TFAData?.enabled && (
                <Button
                  disabled={changingPassword}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="button"
                  onClick={async () => {
                    await handleSubmit();
                    form.reset();
                  }}
                >
                  {changingPassword ? (
                    <CircularProgress size={10} />
                  ) : (
                    t('save')
                  )}
                </Button>
              )}
            </Box>
          </form>
        )}
      </Form>
    </>
  );
};

export default ChangePassword;
