import React, { useEffect } from 'react';
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, redirect } from 'react-router-dom';
import AddAccountFormDialog from './AddAccountFormDialog/AddAccountFormDialog';
import AccountTable from './AccountTable/AccountTable';
import { useGetAccountsQuery } from '../../../features/robot/robotApiSlice';
import { Broker } from '../../../features/robot/types';
import s from './Robot.module.scss';

interface RobotProps {
  brokers: Broker[];
}

const Robot = ({ brokers }: RobotProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: brokerIdStr } = useParams();
  const brokerId = brokerIdStr ? +brokerIdStr : 0;
  const {
    data: accounts,
    isSuccess,
    isLoading: isLoadingAccounts,
  } = useGetAccountsQuery(
    { brokerId },
    { skip: !brokerId, pollingInterval: 30000 }
  );

  useEffect(() => {
    if (brokerIdStr && brokers) {
      if (!brokers.map((broker) => broker.id).includes(Number(brokerIdStr))) {
        navigate(`/robots/${brokers[0].id}`);
      }
    }
  }, [brokerIdStr, brokers, navigate]);

  return (
    <>
      {isLoadingAccounts && (
        <Box sx={{ m: '20% auto', textAlign: 'center' }}>
          <CircularProgress size={64} />
        </Box>
      )}
      {isSuccess && accounts && (
        <div className={s.main}>
          {accounts?.length ? (
            <>
              {accounts?.some((acc) => acc.status?.id === 2) && ( // if we have at least one verified account
                <Box mt="1rem">
                  <Typography variant="body1">
                    {t('subscription_terms', { month_count: '12' })}
                  </Typography>
                </Box>
              )}
              <AddAccountFormDialog
                brokerId={brokerId}
                dialogOpeningComponent={(onClick: () => void) => (
                  <Box mt="1rem" textAlign="right">
                    <Button
                      onClick={onClick}
                      variant="outlined"
                      color="primary"
                      size="large"
                    >
                      + {t('add_account')}
                    </Button>
                  </Box>
                )}
              />
              <AccountTable accounts={accounts} />
            </>
          ) : (
            <Stack mt="2rem" spacing="2rem">
              <Typography variant="body1" ml="1.5rem">
                {t('roboforex_top_text')}
              </Typography>
              <AddAccountFormDialog
                brokerId={brokerId}
                dialogOpeningComponent={(onClick: () => void) => (
                  <Box>
                    <Button
                      onClick={onClick}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      + {t('add_account')}
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          )}
        </div>
      )}
    </>
  );
};

export default Robot;
