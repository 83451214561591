import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Typography } from '@mui/material';
import HistoryWithdraw from './HistoryWithdraw';
import HistoryBuy from './HistoryBuy';
import HistoryDeposit from './HistoryDeposit';
import { Navigate, Route, Routes } from 'react-router-dom';
import TabMenu from '../../components/TabMenu/TabMenu';
import s from './History.module.scss';

export const cptCurrency = { ticker: 'CPT', decimals: 8 };

const History = () => {
  const { t } = useTranslation();

  return (
    <div className={s.content}>
      <Typography variant="h4">{t('history')}</Typography>
      <TabMenu defaultTab="/history/withdraw">
        <Tab label={t('deposit_tab')} value="/history/deposit" disableRipple />
        <Tab label={t('purchase')} value="/history/buy" disableRipple />
        <Tab label={t('withdraw')} value="/history/withdraw" disableRipple />
      </TabMenu>
      <div className={s.content}>
        <Routes>
          <Route path="/withdraw" element={<HistoryWithdraw />} />
          <Route path="/buy" element={<HistoryBuy />} />
          <Route path="/deposit" element={<HistoryDeposit />} />
          <Route path="/" element={<Navigate to="/history/withdraw" />} />
        </Routes>
      </div>
    </div>
  );
};

export default History;
