import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import AppThemeProvider from '../../components/ThemeProvider/AppThemeProvider';
import AppContainer from './AppContainer';

const App = () => (
  <AppThemeProvider>
    <CssBaseline />
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        hideIconVariant
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <AppContainer />
      </SnackbarProvider>
    </BrowserRouter>
  </AppThemeProvider>
);

export default App;
