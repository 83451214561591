import React, { useEffect, useState } from 'react';
import {
  Paper,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Field, Form } from 'react-final-form';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import validate from '../../../utilities/validate';
import schema from './schema';
import Input from '../../../components/Input/Input';
import {
  useGetReferralCodeQuery,
  useSendInvitationsMutation,
} from '../../../features/auth/authApiSlice';
import s from './Invite.module.scss';

const Invite = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();
  const { data: referralCode, isSuccess: codeLoaded } =
    useGetReferralCodeQuery();

  const [
    sendInvitation,
    { isSuccess: invitationSent, isLoading: isSending, isError },
  ] = useSendInvitationsMutation();
  const [isOpen, setOpen] = useState(false);

  const [referralLink, setReferralLink] = useState<string>('');

  const setModalState = (state: boolean) => () => setOpen(state);

  useEffect(() => {
    if (codeLoaded) {
      setReferralLink(referralCode);
    }
  }, [codeLoaded, referralCode]);

  useEffect(() => {
    if (invitationSent) {
      enqueueSnackbar(t('invite_sent'), { variant: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationSent]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('unknown_error'), { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const copyToClipboard = () => {
    copy(referralLink);
    enqueueSnackbar(t('successfully_copied'), { variant: 'success' });
  };

  const onSubmit = async (data: { email: string }) => {
    await sendInvitation(data);
    // setOpen(true);
  };

  const LinkBox = () => (
    <div className={s.link}>
      <Typography variant="body1" color="textSecondary">
        {t('your_ref_code')}
      </Typography>
      <div className={s.linkContent}>
        <Typography variant="h6">{referralLink}</Typography>
        <CopyIcon color={'primary'} onClick={copyToClipboard} />
      </div>
    </div>
  );

  return (
    <>
      <Paper
        className={s.container}
        elevation={0}
        sx={{
          '& .MuiFormHelperText-root': {
            bottom: '-17px',
          },
        }}
      >
        <Form onSubmit={onSubmit} validate={(v) => validate(v, schema())}>
          {({ handleSubmit, form }) => (
            <form className={s.form}>
              <Field
                name="email"
                component={Input}
                label="Email"
                variant="outlined"
                margin="none"
                disabled={isSending}
                showError
              />
              <Button
                onClick={async () => {
                  await handleSubmit();
                  form.reset();
                  form.resetFieldState('email');
                }}
                variant="contained"
                color="primary"
                size="large"
                type="button"
                disabled={isSending}
              >
                {t('invite')}
              </Button>
            </form>
          )}
        </Form>
        <div className={s.right}>{codeLoaded && <LinkBox />}</div>
      </Paper>
      <Paper className={s.linkContainer} elevation={0}>
        <LinkBox />
      </Paper>
      <Dialog open={isOpen} onClose={setModalState(false)}>
        <DialogContent className={s.dialog}>
          <Typography variant="body1">{t('recaptcha_dialog')}</Typography>
          {/* <ReCAPTCHA input={{ onChange: onCaptcha }} /> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Invite;
