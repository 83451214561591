import React from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SunIcon = () => {
  const theme = useTheme();
  return (
    <>
      {theme.palette.mode === 'dark' && (
        <SvgIcon viewBox="0 0 16 16">
          <path
            stroke={theme.palette.text.primary}
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="M7.84868 10.8405c1.79764 0 3.25492-1.45733 3.25492-3.25498 0-1.79764-1.45728-3.25492-3.25492-3.25492-1.79765 0-3.25493 1.45728-3.25493 3.25492 0 1.79765 1.45728 3.25498 3.25493 3.25498z"
          />
          <path
            stroke={theme.palette.text.primary}
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="M7.84863 2.19454V.363647M7.84863 14.9091v-1.8309M11.7139 3.82201l1.3223-1.32232M2.76282 12.7731l1.32231-1.3224M11.7139 11.4507l1.3223 1.3224M2.76282 2.49969l1.32231 1.32232M2.45773 7.58551H.626831M15.1723 7.58551h-1.8309"
          />
        </SvgIcon>
      )}
      {theme.palette.mode === 'light' && (
        <SvgIcon viewBox="0 0 24 24">
          <g
            stroke="#3F86F7"
            strokeMiterlimit="10"
            strokeWidth=".5"
            filter="url(#filter0_d)"
          >
            <path
              fill="#3F86F7"
              d="M11.8487 14.8405c1.7976 0 3.2549-1.4573 3.2549-3.255 0-1.79762-1.4573-3.2549-3.2549-3.2549-1.7977 0-3.25495 1.45728-3.25495 3.2549 0 1.7977 1.45725 3.255 3.25495 3.255z"
            />
            <path d="M11.8488 6.19454V4.36365M11.8488 18.9091v-1.8309M15.7139 7.82201l1.3223-1.32232M6.76282 16.7731l1.32231-1.3224M15.7139 15.4507l1.3223 1.3224M6.76282 6.49969l1.32231 1.32232M6.45773 11.5855h-1.8309M19.1723 11.5855h-1.8309" />
          </g>
          <defs>
            <filter
              id="filter0_d"
              width="22.5455"
              height="22.5455"
              x=".626831"
              y=".363647"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.52549 0 0 0 0 0.968627 0 0 0 1 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </SvgIcon>
      )}
    </>
  );
};

export default SunIcon;
