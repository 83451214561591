import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../utilities/prepareHeaders';
import { baseQueryWithReauth } from './authApi';

const query = fetchBaseQuery({
  prepareHeaders,
  baseUrl: '/',
  credentials: 'include',
});

export const withdrawalApi = createApi({
  reducerPath: 'withdrawal',
  baseQuery: baseQueryWithReauth(query),
  tagTypes: ['Balance'],
  endpoints: () => ({}),
});

export default withdrawalApi;
