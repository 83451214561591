import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import validate from '../../utilities/validate';
import { useEnqueueSnackbarRemoteData } from '../../utilities/snackbarEvents';
import schema from './schema';
import Input from '../../components/Input/Input';
import FieldPassword from '../../components/FieldPassword/FieldPassword';
import { LoginRequest } from '../../features/auth/types';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import trim from '../../utilities/trim/trim';
import s from './Login.module.scss';

const LoginForm = () => {
  const { t } = useTranslation();
  const [sendFrom, { isError, error, isLoading }] = useLoginMutation();

  // const captchaRef = useRef(null);

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  useEffect(() => {
    if (isLoading === false) {
      enqueueSnackbarRemoteData({
        isError,
        error,
        successMessage: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = (values: LoginRequest) => {
    sendFrom(values);
  };

  return (
    <Form onSubmit={onSubmit} validate={(values) => validate(values, schema())}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={s.form}>
          <Field
            type="email"
            name="email"
            id="loginEmail"
            component={Input}
            label="Email"
            variant="outlined"
            formatOnBlur
            format={(value) => trim(value)}
          />
          <FieldPassword
            name="password"
            id="password"
            component={Input}
            type="password"
            label={t('password')}
            variant="outlined"
            formatOnBlur
            format={(value) => trim(value)}
          />
          {/* <Field
            name="g-recaptcha-response"
            component={ReCAPTCHA}
            forwardedRef={captchaRef}
          /> */}
          <Field
            name="captcha"
            component="input"
            type="hidden"
            defaultValue="***"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: 16 }}
            disabled={isLoading}
            fullWidth
          >
            {t('login')}
          </Button>
        </form>
      )}
    </Form>
  );
};

export default LoginForm;
