import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { dispatchSetUserTokens } from '../../features/user/userSlice';
import { Login, Status } from '../../features/auth/types';
import prepareHeaders from '../../utilities/prepareHeaders';
import { deleteCookie } from '../../utilities/cookie';

// const AUTHORIZATION_API = window.config.AUTHORIZATION_API;
const query = fetchBaseQuery({
  prepareHeaders,
  baseUrl: '/',
  credentials: 'include',
});

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const mutex = new Mutex();
export const baseQueryWithReauth =
  (baseQuery: BaseQuery): BaseQuery =>
  async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery(
            {
              url: '/api/auth/authorizations/refresh',
              method: 'POST',
            },
            api,
            extraOptions
          );
          const refreshResultData: Login = (
            refreshResult.data as { data: Login }
          )?.data;
          if (refreshResultData) {
            dispatchSetUserTokens(refreshResultData);
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            const login = await api.dispatch(
              authApi.endpoints.getStatus.initiate()
            );
            if (login.data?.loggedIn) {
              api.dispatch(authApi.endpoints.logout.initiate());
            }
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  };

export const authApi = createApi({
  reducerPath: 'authorization',
  baseQuery: baseQueryWithReauth(query),
  tagTypes: ['Status', 'Mfa', 'User'],
  endpoints: (build) => ({
    getStatus: build.query<Status, void>({
      query: () => ({
        url: '/api/auth/authorizations/status',
        method: 'GET',
      }),
      transformResponse: (response: { data: Status }) => {
        return response.data;
      },
      providesTags: ['Status'],
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/api/auth/authorizations/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        await queryFulfilled;
        deleteCookie('refreshToken');
        document.location.reload();
      },
    }),
  }),
});

export default authApi;
