import React from 'react';

const UnverifiedIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#C62A07" />
      <rect width="2" height="5" x="7" y="4" fill="#fff" rx="1" />
      <circle cx="8" cy="11" r="1" fill="#fff" />
    </svg>
  );
};

export default UnverifiedIcon;
