import { useTranslation } from 'react-i18next';
import { Box, Grid, Link } from '@mui/material';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';

const pdfUrl = `/documents/termofuse/Terms_of_Use.pdf`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={5}
      padding="24px 48px 96px"
      alignItems="center"
      justifyContent="end"
    >
      <Grid item>
        <Box sx={{ display: 'flex', columnGap: '1rem', flexWrap: 'wrap' }}>
          <Link href="mailto:support@cashflowforex.vn">
            support@cashflowforex.vn
          </Link>
          <Link
            variant="body1"
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {t('terms_of_use')}
          </Link>
        </Box>
      </Grid>
      <Grid item>
        <ThemeSwitch />
      </Grid>
      <Grid item>
        <LanguageSelect />
      </Grid>
    </Grid>
  );
};

export default Footer;
