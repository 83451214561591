import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Tab, Box, CircularProgress } from '@mui/material';
import TabMenu from '../../components/TabMenu/TabMenu';
import Robot from './Robot/Robot';
import { useGetBrokersQuery } from '../../features/robot/robotApiSlice';
import s from './Robots.module.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const { data: brokerList, isLoading } = useGetBrokersQuery();

  if (isLoading) {
    return (
      <Box sx={{ m: '20% auto', textAlign: 'center' }}>
        <CircularProgress size={64} />
      </Box>
    );
  }

  if (!brokerList || !(brokerList || []).length) return null;

  return (
    <div className={s.container}>
      <Typography variant="h4">{t('Robots')}</Typography>
      <Routes>
        <Route
          path="/:id"
          element={
            <TabMenu defaultTab={`/robots/${brokerList[0].id}`}>
              {brokerList.map((broker) => {
                return (
                  <Tab
                    key={broker.id}
                    label={
                      <div className={s.labelInfo}>
                        <Typography variant="body1">{broker.name}</Typography>
                      </div>
                    }
                    value={`/robots/${broker.id}`}
                    disableRipple
                  />
                );
              })}
            </TabMenu>
          }
        />
      </Routes>

      <div className={s.content}>
        <Routes>
          <Route path="/:id" element={<Robot brokers={brokerList} />} />
          <Route
            path="/"
            element={<Navigate to={`/robots/${brokerList[0].id}`} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
