import React from 'react';
import { Switch } from '@mui/material';
import { LIGHT_THEME } from '../../themes/theme';
import MoonIcon from '../../resources/icons/MoonIcon';
import SunIcon from '../../resources/icons/SunIcon';
import {
  useGetStatusQuery,
  useUpdateMutation,
} from '../../features/auth/authApiSlice';
import s from './ThemeSwitch.module.scss';

const ThemeSwitch = () => {
  const [update] = useUpdateMutation();
  const { data } = useGetStatusQuery();
  const onSwitch = () => {
    if (!data?.settings) return;
    const theme = data.settings.theme === 'dark' ? 'light' : 'dark';
    update({ ...data.settings, theme });
  };

  return data?.loggedIn ? (
    <>
      <div className={s.container}>
        <div className={s.theme}>
          <MoonIcon />
          <Switch
            checked={data?.settings.theme === LIGHT_THEME}
            onChange={onSwitch}
            value="selectedTheme"
            color="primary"
          />
          <SunIcon />
        </div>
      </div>
    </>
  ) : null;
};

export default ThemeSwitch;
