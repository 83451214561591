import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from 'lodash';
import { Button, Typography } from '@mui/material';
import numeral from 'numeral';
import 'numeral/locales/ru';
import 'numeral/locales/vi';
import {
  useGetStatusQuery,
  useUpdateMutation,
} from '../../features/auth/authApiSlice';
import s from './LanguageSelect.module.scss';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [update] = useUpdateMutation();
  const { data } = useGetStatusQuery();
  const language = i18n.language.split('-')[0];

  numeral.locale(language);

  // language control for recaptcha
  window.recaptchaOptions = { lang: language, removeOnUnmount: true };

  useEffect(() => {
    if (data?.settings.language) {
      i18n.changeLanguage(data.settings.language);
    }
  }, [data?.settings.language, i18n]);

  const changeLanguage = (lang) => () => {
    window.recaptchaOptions.lang = lang;
    i18n.changeLanguage(lang);
    if (data.loggedIn) {
      update({ ...data.settings, language: lang });
    }
  };

  return (
    <div className={s.container}>
      {keys(i18n.options.resources).map((i) => (
        <Typography
          variant="body1"
          onClick={changeLanguage(i)}
          color={language !== i ? 'primary' : 'default'}
          key={i}
        >
          {i}
        </Typography>
      ))}
    </div>
  );
};

export default LanguageSelect;
