import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { InputAdornment } from '@mui/material';
import Input from '../Input/Input';
import { FieldRenderProps } from 'react-final-form';

const NumberInput = ({
  endAdornment,
  inputProps,
  onlyNumbers,
  maxDecimals,
  ...props
}: FieldRenderProps<Nullable<string | number>>) => {
  const [isControl, setControl] = useState(false);

  const filter = (event: KeyboardEvent<HTMLInputElement>) => {
    const e = event as ChangeEvent<HTMLInputElement> &
      KeyboardEvent<HTMLInputElement>;
    const NUMBER_DOT_COMMA = /^[\d,.]*$/;
    const fieldValue = e.target.value.replace(/[^\d,.]+/g, '');
    const fieldHasCommaOrDot =
      fieldValue.includes('.') || fieldValue.includes(',');
    const keyIsCommaOrDot = e.key === '.' || e.key === ',';

    if (
      !NUMBER_DOT_COMMA.test(e.key) ||
      (keyIsCommaOrDot && fieldHasCommaOrDot)
    ) {
      e.preventDefault();
    }

    if (
      fieldHasCommaOrDot &&
      (e.target.value.split('.')[1] || []).length === (maxDecimals || 8)
    ) {
      e.preventDefault();
    }

    e.target.value = fieldValue;
    props.input.onChange(fieldValue);
  };

  const formatText = (e: KeyboardEvent<HTMLInputElement>) => {
    const returnKeyCodes = [
      'Backspace',
      'Tab',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ];

    if (['Control', 'Meta'].includes(e.key)) {
      setControl(true);
    }

    if (isControl && ['v', 'c', 'x'].includes(e.key)) {
      return;
    }

    if (returnKeyCodes.includes(e.key)) {
      return;
    }

    filter(e);
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isControl) {
      filter(e);
    }
    setControl(false);
  };

  const onlyNumbersProps = {
    type: 'number',
    min: 0,
    step: 1,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        return;
      }
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    },
  };
  return (
    <Input
      placeholder="0.00"
      InputProps={{
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : null,
      }}
      inputProps={{
        onKeyDown: formatText,
        onKeyUp,
        ...(onlyNumbers ? onlyNumbersProps : {}),
        ...inputProps,
      }}
      {...props}
    />
  );
};

export default NumberInput;
