import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import s from './TransactionItem.module.scss';
import { useTranslation } from 'react-i18next';
import { DARK_THEME } from '../../../themes/theme';

export enum FieldNames {
  fee = 'fee',
  address = 'address',
}

interface TableHeaderProps {
  hide?: FieldNames[];
  isIncomeData?: boolean;
}

const TableHeader = ({ hide = [], isIncomeData = false }: TableHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      className={s.header}
      sx={{
        backgroundColor:
          theme.palette.mode === DARK_THEME ? '#656669' : 'inherit',
        '& p': {
          fontWeight: 600,
        },
      }}
    >
      <Typography variant="body1">{t('date')}</Typography>
      <Typography variant="body1">{t('time')}</Typography>
      <Typography variant="body1">{t('status')}</Typography>
      <Typography variant="body1">{t('sum')}</Typography>
      {isIncomeData ? (
        <Typography variant="body1">{t('account')}</Typography>
      ) : (
        <Typography variant="body1">
          {!hide?.includes(FieldNames.fee) && t('fee')}
        </Typography>
      )}
      <Typography variant="body1">
        {!hide?.includes(FieldNames.address) && t('address')}
      </Typography>
    </Box>
  );
};

export default TableHeader;
