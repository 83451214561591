import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  typography: {
    fontFamily: 'SF Pro Text',
    fontSize: 12,
  },
  palette: {
    mode: 'dark',
    divider: '#303337',
    background: { default: '#252628', paper: '#303337' },
    primary: {
      main: '#3F86F7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#23B816',
      contrastText: '#fff',
    },
    error: {
      main: '#f24773',
    },
    text: {
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          borderBottomColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: 14,
        },
        icon: {
          color: '#fff',
          right: 14,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: 'transparent',
          '&:-webkit-autofill,:-webkit-autofill:active,:-webkit-autofill:focus,:-webkit-autofill:hover':
            {
              WebkitTextFillColor: '#fff',
              WebkitTransitionDelay: '99999s',
            },
        },
      },
    },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       color: '#fff',
    //     },
    //   },
    // },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderColor: '#3F86F7',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          height: 32,
          textTransform: 'none',
          paddingTop: 4,
          paddingBottom: 4,
        },
        outlinedPrimary: {
          paddingTop: 3,
          paddingBottom: 3,
          color: '#fff',
          borderWidth: 1,
          backgroundColor: 'transparent',
          '&:hover': {
            borderWidth: 1,
            backgroundColor: '#3F86F7',
          },
        },
        outlined: {
          backgroundColor: 'transparent',
        },
        contained: {
          boxShadow: 'none',
          '&$disabled': {
            boxShadow: 'none !important',
          },
        },
        containedPrimary: {
          '&$sizeLarge': {
            boxShadow: '0px 0px 14px rgba(63, 134, 247, 0.5)',
          },
        },
        sizeLarge: {
          height: 42,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& $notchedOutline': {
            borderColor: '#3F86F7',
            '& $disabled': {
              borderColor: '#999 !important',
            },
          },
          '& $disabled': {
            color: '#999',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: '#999',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        fixed: {
          borderBottom: '1px solid #3A3F45',
          overflowX: 'unset',
          paddingBottom: 4,
        },
        indicator: {
          bottom: -1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginRight: 28,
          '@media (min-width: 960px)': {
            minWidth: 'unset',
          },
        },
        // TODO
        // label: {
        //   fontSize: 14,
        // },
        // labelContainer: {
        //   padding: '4px 0 !important',
        // },
        textColorInherit: {
          opacity: 1,
          textAlign: 'left',
          minHeight: 16,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&$disabled': {
            '& *': {
              color: 'rgba(255, 255, 255, 0.3)',
            },
          },
        },
        colorPrimary: {
          '& *': {
            color: '#3F86F7',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&$disabled': {
            '& *': {
              color: 'rgba(255, 255, 255, 0.5)',
            },
          },
        },
        colorPrimary: {
          '& *': {
            color: '#3F86F7',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: 12,
          color: '#fff',
        },
      },
    },
  },
});

export default darkTheme;
