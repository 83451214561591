import { useAppSelector } from '../redux/hooks';
import { AppState } from '../../app/store';
import { useGetBalanceQuery } from '../../features/depository/depositoryApiSlice';

const useUserBalance = () => {
  const reduxBalance = useAppSelector((state: AppState) => state.balance);
  const { data: balance } = useGetBalanceQuery();
  const userBalance = reduxBalance.balance.amount || balance?.amount;

  return userBalance;
};

export default useUserBalance;
