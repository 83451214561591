/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker as DRP, DateRange } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { endOfDay, format } from 'date-fns';
import { TextField, InputAdornment } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import cx from 'classnames';
import * as locales from 'react-date-range/src/locale';
import { withStyles } from '@mui/styles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getStaticRanges } from '../../utilities/staticRanges';
import useMediaQuery from '@mui/material/useMediaQuery';
import s from './DateRangePicker.module.scss';

const styles = (theme) => {
  const rdrStaticRange = {
    backgroundColor: theme.palette.background.paper,
    borderColor: 'transparent',
    color: theme.palette.text.primary,
    '&:hover > span': {
      color: theme.palette.primary.main,
    },
  };

  const rdrDefinedRangesWrapper = {
    backgroundColor: theme.palette.background.paper,
    borderColor: 'transparent',
  };

  return {
    calendar: {
      borderColor: theme.palette.primary.main,
      '& .rdrCalendarWrapper': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      },
      '& .rdrDayNumber span': {
        color: theme.palette.text.primary,
      },
      '& .rdrDayPassive .rdrDayNumber span': {
        color: theme.palette.text.disabled,
      },
      '& .rdrPprevButton i': {
        borderRightColor: theme.palette.primary.main,
      },
      '& .rdrNextButton i': {
        borderLeftColor: theme.palette.primary.main,
      },
      '& .rdrDayDisabled span': {
        color: theme.palette.text.disabled,
      },
    },
    bigCalendar: {
      '& .rdrStaticRange': { ...rdrStaticRange },
      '& .rdrDefinedRangesWrapper': {
        ...rdrDefinedRangesWrapper,
        width: '150px !important',
      },
    },
    smallCalendar: {
      flexDirection: 'column',
      '& .rdrStaticRange': {
        ...rdrStaticRange,
        '& .rdrStaticRangeLabel': {
          padding: '5px',
        },
      },
      '& .rdrStaticRanges': {
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
      '& .rdrDefinedRangesWrapper': {
        ...rdrDefinedRangesWrapper,
        width: '100% !important',
        borderBottomLeftRadius: '0',
        borderTopRightRadius: '10px',
      },
    },
    input: {
      maxHeight: 36,
      cursor: 'pointer',
    },
    inputBase: {
      cursor: 'pointer',
    },
  };
};

const DateRangePicker = ({ theme, classes, input: { onChange, value } }) => {
  const datePickerRef = useRef();
  const [inputFocused, setInputFocused] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    ...value,
    key: 'selection',
    color: theme.palette.primary.main,
  });
  const isWideScreen = useMediaQuery('(min-width:1150px)');

  const { i18n } = useTranslation();

  const handleClickOutside = (e) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      setInputFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleChange = (values) => {
    values.selection.endDate = endOfDay(values.selection.endDate);
    setDateRange(values.selection);
    onChange?.(values.selection);
  };

  const handleFocus = (val) => () => setInputFocused(val);

  const getDateValue = () => {
    return `${format(dateRange.startDate, 'dd.MM.yyyy')} - ${format(
      dateRange.endDate,
      'dd.MM.yyyy'
    )}`;
  };

  return (
    <div className={s.dateRange}>
      <TextField
        variant="outlined"
        name="period"
        value={getDateValue()}
        onFocus={handleFocus(true)}
        className={s.input}
        InputProps={{
          classes: {
            root: classes.input,
            input: classes.inputBase,
          },
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon style={{ fontSize: 14 }} />
            </InputAdornment>
          ),
        }}
      />
      {inputFocused && (
        <div className={s.picker} ref={datePickerRef}>
          <DRP
            ranges={[dateRange]}
            onChange={handleChange}
            showMonthAndYearPickers={false}
            className={cx(
              s.rdrCustom,
              classes.calendar,
              isWideScreen ? classes.bigCalendar : classes.smallCalendar
            )}
            locale={locales[i18n.language.split('-')[0]]}
            staticRanges={getStaticRanges()}
            maxDate={new Date()}
            months={2}
            direction={isWideScreen ? 'horizontal' : 'vertical'}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DateRangePicker);
