import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress } from '@mui/material';
import { identity } from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
import validate from '../../../utilities/validate';
import schema from './schema';
import Input from '../../../components/Input/Input';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import {
  useGetMfaEnabledQuery,
  useGetUserInfoQuery,
  useSetUserInfoMutation,
} from '../../../features/auth/authApiSlice';
import { UserDataFormValues } from '../../../features/auth/types';
import { useEnqueueSnackbarRemoteData } from '../../../utilities/snackbarEvents';
import TfaConfirm from '../../../components/TFAConfirm/TFAConfirm';

const Profile = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetUserInfoQuery();
  const [
    setUserInfo,
    { error, isError, isSuccess, isLoading: isLoadingSetUserInfo },
  ] = useSetUserInfoMutation();
  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();
  const { data: TFAData, isSuccess: tfaLoaded } = useGetMfaEnabledQuery();

  useEffect(() => {
    enqueueSnackbarRemoteData({
      isError,
      error,
      successMessage: isSuccess ? t('success_changes_saved') : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError, isSuccess]);

  const initialValues = data;

  if (isLoading) {
    return <CircularProgress />;
  }

  const onSubmit = async (values: UserDataFormValues) => {
    await setUserInfo({ ...values, otpCode: values.tfa_code });
  };

  return (
    <>
      {tfaLoaded && (
        <>
          <Form
            onSubmit={onSubmit}
            validate={(values) => validate(values, schema())}
            initialValues={initialValues}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="firstName"
                  component={Input}
                  label={t('first_name')}
                  variant="outlined"
                  parse={identity}
                  showError
                />
                <Field
                  name="lastName"
                  component={Input}
                  label={t('last_name')}
                  variant="outlined"
                  parse={identity}
                  showError
                />
                <Field
                  name="phone"
                  component={PhoneInput}
                  label={t('phone')}
                  validate={(value) =>
                    isValidPhoneNumber(value || '')
                      ? undefined
                      : 'invalid_phone'
                  }
                  showError
                />
                <Box mt="16px">
                  {TFAData.enabled && <TfaConfirm />}
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={isLoadingSetUserInfo}
                  >
                    {t('save')}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </>
      )}
    </>
  );
};

export default Profile;
