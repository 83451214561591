import * as yup from 'yup';
import { t } from 'i18next';

const schema = () =>
  yup.object({
    email: yup
      .string()
      .email(t('email_not_valid') || ' ')
      .required(),
  });

export default schema;
