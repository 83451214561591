import React from 'react';
import { format } from 'date-fns';
import { Typography, Box } from '@mui/material';
import numbro from 'numbro';
import cx from 'classnames';
import failIcon from '../../../resources/icons/failIcon.png';
import completedIcon from '../../../resources/icons/completedIcon.png';
import pendingIcon from '../../../resources/icons/pendingIcon.png';
import {
  HistoryItem,
  HistoryItemStatusName,
} from '../../../features/withdrawal/types';
import s from './TransactionItem.module.scss';
import { useTranslation } from 'react-i18next';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
interface TransactionItemProps {
  item: Optional<
    HistoryItem,
    'currency' | 'address' | 'message' | 'txHash' | 'fee'
  >;
  isIncomeData?: boolean;
}

const {
  PENDING,
  WAITING,
  PROCESSING,
  WAITING_CONFIRMATIONS,
  CONVERTING,
  SUCCESS,
  DONE,
  COMPLETED,
  ERROR,
  FAILED,
} = HistoryItemStatusName;

const TransactionItem = ({
  item,
  isIncomeData = false,
}: TransactionItemProps) => {
  const { t } = useTranslation();
  return (
    <Box className={s.item}>
      <Typography variant="body1">
        {format(new Date(item.timestamp), 'dd.MM.yyyy')}
      </Typography>
      <Typography variant="body1">
        {format(new Date(item.timestamp), 'HH:mm')}
      </Typography>
      <Box display="flex" alignItems="center">
        {[SUCCESS, DONE, COMPLETED].includes(item.status.name) && (
          <img src={completedIcon} alt="Success" />
        )}
        {[
          PENDING,
          WAITING,
          PROCESSING,
          WAITING_CONFIRMATIONS,
          CONVERTING,
        ].includes(item.status.name) && <img src={pendingIcon} alt="Pending" />}
        {[ERROR, FAILED].includes(item.status.name) && (
          <img src={failIcon} alt="Error" />
        )}
      </Box>
      <Typography variant="body1" component={Box}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={s.columnName}
        >
          {t('sum')}
        </Typography>
        {numbro(item.amount).format({
          thousandSeparated: true,
          trimMantissa: true,
          mantissa: 8,
        })}{' '}
        {item.currency || 'USD'}
      </Typography>
      {item.fee ? (
        <Typography variant="body1" component={Box}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={s.columnName}
          >
            {t('fee')}
          </Typography>
          {numbro(item.fee).format({
            thousandSeparated: true,
            trimMantissa: true,
            mantissa: 8,
          })}{' '}
          {item.currency || 'USD'}
        </Typography>
      ) : (
        <Typography className={s.address} variant="body1" color="textSecondary">
          {isIncomeData && item.message}
        </Typography>
      )}
      <Typography
        className={cx(s.address, item.address && s.topMargin)}
        variant="body1"
        color="textSecondary"
      >
        {item.address}
      </Typography>
    </Box>
  );
};

export default TransactionItem;
