import React from 'react';
import { isNil } from 'lodash';
import UnverifiedIcon from '../../resources/icons/UnverifiedIcon';
import { FieldRenderProps } from 'react-final-form';
import s from './withVerify.module.scss';

type Props = FieldRenderProps<Nullable<string | number>>;

const withVerify = (Component: React.ComponentType<Props>) => {
  function WithVerify({ ...props }: Props) {
    return props.requireToVerify && props.requireToVerify(props.input.name) ? (
      <div className={s.container}>
        <Component {...props} />
        {(isNil(props.input.value) ||
          !props.input.value.toString().length ||
          (props.validValues
            ? !props.validValues.includes(props.input.value)
            : false)) && <UnverifiedIcon className={s.icon} />}
      </div>
    ) : (
      <Component {...props} />
    );
  }

  return WithVerify;
};

export default withVerify;
