import React, { useEffect } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetBalanceQuery } from '../../features/depository/depositoryApiSlice';
import { useAppSelector } from '../../hooks/redux/hooks';
import { AppState } from '../../app/store';
import SignalRService from '../../utilities/webSockets/webSockets';
import { dispatchUserBalance } from '../../features/balance/balanceSlice';

const Balance = () => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess: gotBalance } = useGetBalanceQuery();
  const token = useAppSelector(
    (state: AppState) => state.user?.login?.accessToken
  );
  const reduxBalance = useAppSelector((state: AppState) => state.balance);

  useEffect(() => {
    const signalRService = new SignalRService();
    if (gotBalance && token) {
      signalRService.startConnection('/api/usd/update', token);

      signalRService.registerEventHandler('update', dispatchUserBalance);
    }

    return () => {
      signalRService.closeConnection();
    };
  }, [gotBalance, token]);

  return (
    <>
      <Typography variant="body1">
        {t('balance')}
        {` `}
        {isLoading ? (
          <CircularProgress size={14} />
        ) : (
          reduxBalance?.balance?.amount || data?.amount
        )}
        {` `}
        USD
      </Typography>
    </>
  );
};

export default Balance;
