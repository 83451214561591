import React from 'react';

const RegisterSuccess = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
      <path
        fill="#147eef"
        d="M46 .666667c-24.9 0-45 20.1-45 45s20.1 45 45 45 45-20.1 45-45-20.1-45-45-45zm-7 62l-3-3-15.2-15.4 2.8-2.8 15.4 15.3 29.1-28.3 2.8 2.8-31.9 31.4z"
      />
    </svg>
  );
};

export default RegisterSuccess;
