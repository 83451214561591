import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

class SignalRService {
  connection: Nullable<HubConnection>;

  constructor() {
    this.connection = null;
  }

  startConnection = (url: string, token: string) => {
    this.connection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        accessTokenFactory: () => token,
        transport: HttpTransportType.WebSockets,
        logMessageContent: true,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
    this.connection.serverTimeoutInMilliseconds = 60000;
    this.connection
      .start()
      .then(() => {
        console.log('SignalR connection started');
      })
      .catch((err) =>
        console.error('Error starting SignalR connection: ', err)
      );
  };

  registerEventHandler = (
    channel: string,
    callback: (data?: unknown) => void
  ) => {
    if (this.connection) {
      this.connection.on(channel, (message) => {
        callback(message);
      });
    }
  };

  closeConnection = () => {
    if (this.connection) {
      this.connection
        .stop()
        .then(() => console.log('SignalR connection stopped'))
        .catch((err) =>
          console.error('Error stopping SignalR connection: ', err)
        );
    }
  };
}

export default SignalRService;
