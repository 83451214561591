import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  TextField,
  CircularProgress,
  Box,
} from '@mui/material';
import { InputAdornment } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { useGetAddressQuery } from '../../features/cashIn/cashInApiSlice';

interface GetTransferDialogProps {
  children?: ({
    openModal,
  }: {
    openModal: (state: boolean) => void;
  }) => React.ReactNode;
}

const GetTransferDialog = ({ children }: GetTransferDialogProps) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: address, isSuccess, isLoading } = useGetAddressQuery();

  const setModalState = (state: boolean) => () => setOpen(state);

  const copyToClipboard = (value: string) => () => {
    copy(value);
    enqueueSnackbar(t('successfully_copied'), { variant: 'success' });
  };

  return (
    <>
      {children ? (
        children({ openModal: setModalState(true) })
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={setModalState(true)}
        >
          {t('deposit')}
        </Button>
      )}
      <Dialog open={isOpen} onClose={setModalState(false)}>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{ mb: '20px', mt: 1, fontWeight: 'bold' }}
          >
            {t('deposit_warn', { from: 'USD', to: 'Tether USDT ERC-20' })}
          </Typography>
          {isSuccess && address && (
            <TextField
              margin="none"
              fullWidth
              variant="outlined"
              disabled
              value={address.address}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={copyToClipboard(address.address)}>
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            onClick={setModalState(false)}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GetTransferDialog;
