import { t } from 'i18next';
import { useSnackbar } from 'notistack';

export interface ErrorData {
  data: {
    detail: string;
    instance: string;
    status: number;
    title: string;
    type: string;
  };
}

export function useEnqueueSnackbarRemoteData() {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSnackbarRemoteData = ({
    isError,
    successMessage,
    error,
  }: {
    isError: boolean;
    successMessage: string;
    error: any;
  }) => {
    if (isError) {
      if (typeof error === 'string') {
        enqueueSnackbar(t(error), {
          variant: 'error',
        });
      } else {
        const errorData = error as unknown as ErrorData;

        enqueueSnackbar(errorData?.data?.title || 'Error', {
          variant: 'error',
        });
      }
    } else if (successMessage) {
      enqueueSnackbar(successMessage, { variant: 'success' });
    }
  };

  return { enqueueSnackbarRemoteData };
}
