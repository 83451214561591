import React, { useEffect } from 'react';
import cx from 'classnames';
import { Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import Input from '../Input/Input';
import s from './TFAConfirm.module.scss';
import { useGetMfaEnabledQuery } from '../../features/auth/authApiSlice';
import TFA from '../../containers/Settings/TFA/TFA';

const TfaConfirm = () => {
  const { t } = useTranslation();
  const { data: TFAData, isSuccess } = useGetMfaEnabledQuery();

  const props = {
    requestCode: () => {},
    resetRequestCode: () => {},
    tfa: {
      isRequest: false,
      isSuccess: false,
      stateError: false,
    },
    ui: {
      isRequest: false,
      isSuccess: false,
      stateError: false,
    },
    className: '',
    name: 'tfa_code',
    disabled: false,
    isOperation: false,
  };

  useEffect(() => {
    return () => {
      props.resetRequestCode();
    };
  }, []);

  const getTypeLabel = (type) => {
    switch (type) {
      case 'ga':
        return t('tfa_required_destination_ga');
      case 'email':
        return t('tfa_required_destination_email');
      case 'sms':
        return t('tfa_required_destination_sms');
      default:
        return '';
    }
  };

  const onFormStateChange = (formState) => {
    if (formState.submitSucceeded) {
      props.resetRequestCode();
    }
  };

  return (
    <>
      <FormSpy
        onChange={onFormStateChange}
        subscription={{ submitSucceeded: true, dirtySinceLastSubmit: true }}
      />
      <div className={cx(s.container, props.className)}>
        <Typography variant="h6" gutterBottom>
          {t('tfa_code')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {props.isOperation ? t('tfa_required_operation') : t('tfa_required')}{' '}
          {getTypeLabel(props.ui.data?.['2fa_type'])}
        </Typography>
        {isSuccess && TFAData?.enabled ? (
          <div className={s.code}>
            <Field
              name={props.name || '2fa_code'}
              component={Input}
              label={t('2fa_code')}
              margin="none"
              variant="outlined"
              fullWidth={false}
              disabled={props.disabled}
            />
            {props.ui.data?.['2fa_type'] !== 'ga' && (
              <div className={s.button}>
                {props.tfa.isSuccess && (
                  <Typography variant="body2">{t('tfa_success')}</Typography>
                )}
                {props.tfa.stateError && (
                  <Typography variant="body2">
                    {t(props.tfa.stateError)}
                  </Typography>
                )}
              </div>
            )}
          </div>
        ) : (
          <Box mt="16px">
            <TFA />
          </Box>
        )}
      </div>
    </>
  );
};

export default TfaConfirm;
