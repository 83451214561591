import React from 'react';
import { SvgIcon } from '@mui/material';

const LogoutIcon = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 38 34" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M19.8235 1H17C8.16345 1 1 8.16344 1 17c0 8.8366 7.16345 16 16 16h2.8235M28.2941 9.47058L35.8235 17l-7.5294 7.5294M13.2354 17h20.7058"
      />
    </SvgIcon>
  );
};

export default LogoutIcon;
