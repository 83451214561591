import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DARK_THEME } from '../../../../../themes/theme';
import s from './AccountTableRow.module.scss';

type AccountTableHeaderProps = {
  data: {
    accountId: string;
    status: string;
    robot: string;
    ownership: string;
    actions: string;
  };
};

const AccountTableHeader = ({ data }: AccountTableHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      className={s.main}
      sx={{
        backgroundColor:
          theme.palette.mode === DARK_THEME ? '#656669' : 'inherit',
        '& p': {
          fontWeight: 600,
        },
      }}
    >
      <Typography variant="body1">{data.accountId}</Typography>
      <Typography variant="body1">{data.status}</Typography>
      <Typography variant="body1">{data.robot}</Typography>
      <Typography variant="body1">{data.ownership}</Typography>
      <Typography variant="body1" className={s.lastColumn}>
        {data.actions}
      </Typography>
    </Box>
  );
};

export default AccountTableHeader;
