import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import type { AppState } from '../app/store';
import i18n from '../locale/i18n';

type Signature = (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => MaybePromise<Headers>;

/**
 * Prepare request headers for RTK Query
 * Set an authorization header if accessToken is exist in store
 *
 * @param {Headers} headers - base request headers
 * @param {Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' 'forced'>} data -
 * picked properties from BaseQueryApi
 * @returns {MaybePromise<Headers>} updated request headers
 */
const prepareHeaders: Signature = (headers, { getState }) => {
  const token = (getState() as AppState).user.login.accessToken;

  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }

  headers.set('Accept-Language', i18n.language);
  headers.set('Content-Type', 'application/json');

  return headers;
};

export default prepareHeaders;
