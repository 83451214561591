import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from '../../app/store';
import { Login } from '../../features/auth/types';
import { Balance } from '../depository/types';

const initialState: {
  balance: Balance;
} = {
  balance: {
    amount: NaN,
  },
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    setBalance: (state, action: PayloadAction<any>) => {
      state.balance = action.payload;
    },
  },
});

const { setBalance } = balanceSlice.actions;

export const getTokens = (): Login => {
  const state = store.getState();
  return state.user.login;
};

export const dispatchUserBalance = (data: any) => {
  store.dispatch(setBalance(data));
};

export default balanceSlice.reducer;
