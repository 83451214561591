import React, { useState } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const InputPassword = ({
  type,
  ...otherProps
}: FieldProps<string, FieldRenderProps<string>>) => {
  const [fieldType, setFieldType] = useState<'password' | 'text'>('password');

  const adornmentHandleClick = () => {
    setFieldType((prevState) =>
      prevState === 'password' ? 'text' : 'password'
    );
  };

  return (
    <Field
      {...otherProps}
      type={fieldType}
      endAdornment={
        <InputAdornment position="end" variant="filled">
          <IconButton disableRipple onClick={adornmentHandleClick}>
            {fieldType === 'password' ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default InputPassword;
