import withdrawalApi from '../../app/api/withdrawalApi';
import {
  HistoryRequest,
  WithdrawRequest,
  EstimationFeeRequest,
  EstimationFee,
  Withdraw,
  History,
} from './types';

const withdrawalApiSlice = withdrawalApi.injectEndpoints({
  endpoints: (build) => ({
    getHistory: build.query<History, HistoryRequest>({
      query: (params) => ({
        url: '/api/withdrawal/history',
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: History }) => {
        return response.data;
      },
    }),
    withdraw: build.mutation<Withdraw, WithdrawRequest>({
      query: (body) => ({
        url: '/api/withdrawal/withdraw',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Balance'],
      transformResponse: (response: { data: Withdraw }) => {
        return response.data;
      },
    }),
    getEstimationFee: build.mutation<EstimationFee, EstimationFeeRequest>({
      query: (body) => ({
        url: '/api/withdrawal/get-estimation-fee',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: EstimationFee }) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazyGetHistoryQuery,
  useWithdrawMutation,
  useGetEstimationFeeMutation,
} = withdrawalApiSlice;
