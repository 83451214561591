import bonusesApi from '../../app/api/bonusesApi';
import { Tree, TreeRequest } from './types';

const bonusesApiSlice = bonusesApi.injectEndpoints({
  endpoints: (build) => ({
    getBalance: build.query<Tree, TreeRequest>({
      query: (params) => ({
        url: '/api/bonus/tree',
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: Tree }) => {
        return response.data;
      },
    }),
  }),
});

export const { useLazyGetBalanceQuery } = bonusesApiSlice;
