import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, CircularProgress } from '@mui/material';
import {
  useGetMfaEnabledQuery,
  useMfaEnabledPreMutation,
} from '../../../features/auth/authApiSlice';
import QrLinkDialog from '../QrLinkDialog/QrLinkDialog';

const TFA = () => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const { data, isLoading } = useGetMfaEnabledQuery();
  const [mfaEnabledPre, { data: href = '', isSuccess }] =
    useMfaEnabledPreMutation();

  useEffect(() => {
    if (isSuccess) {
      setOpenDialog(true);
    }
  }, [isSuccess]);

  const submit = () => {
    if (data?.enabled) {
      setOpenDialog(true);
    } else {
      mfaEnabledPre();
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data) return null;

  return (
    <Box>
      <Button
        onClick={submit}
        variant="contained"
        color={data?.enabled ? 'error' : 'primary'}
        type="button"
        size="large"
      >
        {data?.enabled ? t('delete_tfa') : t('enable_tfa')}
      </Button>
      <QrLinkDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        href={href}
      />
    </Box>
  );
};

export default TFA;
