import React from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const MoonIcon = () => {
  const theme = useTheme();
  return (
    <>
      {theme.palette.mode === 'dark' && (
        <SvgIcon viewBox="0 0 11 14">
          <path
            fill="#3F86F7"
            d="M4.24214 1.39999c-.29474 0-.63158.04211-.92632.08421C4.7474 2.49473 5.67371 4.17894 5.67371 6.07368c0 2.77895-2.02105 5.05262-4.67368 5.51582.92632.6315 2.02105 1.0105 3.2 1.0105 3.07368 0 5.6-2.4842 5.6-5.60001.04211-3.11579-2.48421-5.6-5.55789-5.6z"
            filter="url(#filter0_d)"
          />
          <defs>
            <filter
              id="filter0_d"
              width="10.8005"
              height="13.2"
              x=".00003052"
              y=".399994"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset />
              <feGaussianBlur stdDeviation=".5" />
              <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.52549 0 0 0 0 0.968627 0 0 0 1 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </SvgIcon>
      )}
      {theme.palette.mode === 'light' && (
        <SvgIcon viewBox="0 0 12 14">
          <path
            stroke="#757575"
            d="M5.24211 1.39999c-.29474 0-.63158.04211-.92632.08421 1.43158 1.01053 2.35789 2.69474 2.35789 4.58948 0 2.77895-2.02105 5.05262-4.67368 5.51582.92632.6315 2.02105 1.0105 3.2 1.0105 3.07368 0 5.6-2.4842 5.6-5.60001.0421-3.11579-2.48421-5.6-5.55789-5.6z"
          />
        </SvgIcon>
      )}
    </>
  );
};

export default MoonIcon;
