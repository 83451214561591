import * as yup from 'yup';
import i18n from '../../locale/i18n';

const schema = () =>
  yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    captcha: yup
      .string()
      .nullable()
      .required(i18n.t('captcha_required') || undefined),
  });

export default schema;
