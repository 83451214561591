import React from 'react';
import { useTranslation } from 'react-i18next';
import LeftMenuItem from './LeftMenuItem';
import DashboardIcon from '../../resources/icons/DashboardIcon';
import BonusesIcon from '../../resources/icons/BonusesIcon';
import SettingsIcon from '../../resources/icons/SettingsIcon';
import HistoryIcon from '../../resources/icons/HistoryIcon';
// import SupportIcon from '../../resources/icons/SupportIcon';
import s from './LeftMenu.module.scss';

const LeftMenu = () => {
  const { t } = useTranslation();

  // TODO
  const isUnverified = false;
  const isMessengersNeedAttention = false;

  return (
    <div className={s.container}>
      <LeftMenuItem icon={<DashboardIcon />} title={t('robots')} to="/robots" />
      <LeftMenuItem icon={<BonusesIcon />} title={t('bonuses')} to="/bonuses" />
      <LeftMenuItem
        icon={<SettingsIcon />}
        title={t('settings')}
        unverified={isMessengersNeedAttention || isUnverified}
        to="/settings"
      />
      <LeftMenuItem icon={<HistoryIcon />} title={t('history')} to="/history" />
      {/* <LeftMenuItem icon={<SupportIcon />} title={t('support')} to="/support" /> */}
    </div>
  );
};

export default LeftMenu;
