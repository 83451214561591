import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import s from './Terms.module.scss';

interface TermsProps {
  referral: boolean;
  bodyKey: string;
}

const Terms = ({ referral, bodyKey }: TermsProps) => {
  const { t } = useTranslation();

  return (
    <Accordion
      className={s.container}
      elevation={0}
      sx={{
        '& div:nth-of-type(2).MuiCollapse-hidden': {
          transition: '.3s',
          maskImage: 'linear-gradient(rgba(0, 0, 0, 1) 40%, transparent 95%)',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          {t(referral ? 'referral_terms_title' : 'terms_of_sale_title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: t(bodyKey ?? 'terms_of_sale_content'),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Terms;
