import React from 'react';
import {
  TextField,
  FormHelperText,
  FormControl,
  BaseTextFieldProps,
  InputClasses,
} from '@mui/material';
import withVerify from '../withVerify/withVerify';
import { NO_MESSAGE } from '../../constants';
import { FieldRenderProps } from 'react-final-form';

const Input = (props: FieldRenderProps<Nullable<string | number>>) => {
  const {
    input: { name, onChange, value, ...restInput },
    classes,
    meta,
    endAdornment,
    useHelperText,
    inputProps,
    title,
    showError,
    initialValue,
    size,
    hidden,
    innerRef,
    ...rest
  } = props;

  const inputLabelSizeProps: BaseTextFieldProps['InputLabelProps'] = {};
  const inputSizeProps: { classes?: Partial<InputClasses> } = {};

  if (size === 'small') {
    inputLabelSizeProps.classes = { outlined: classes.label };
    inputSizeProps.classes = { root: classes.root };
  }

  if (hidden) return null;

  return (
    <FormControl fullWidth>
      <TextField
        sx={{ position: 'relative' }}
        error={meta.error && meta.touched}
        inputProps={{ ...inputProps, required: false }}
        margin="normal"
        fullWidth
        ref={innerRef}
        InputProps={{
          endAdornment,
          ...inputSizeProps,
          ...restInput,
        }}
        InputLabelProps={{ ...inputLabelSizeProps }}
        name={name}
        onChange={onChange}
        value={value}
        {...rest}
      />
      {showError && meta.error && meta.error !== NO_MESSAGE && meta.touched && (
        <FormHelperText
          sx={{ m: 0, position: 'absolute', bottom: '-10px' }}
          error
        >
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withVerify(Input);
