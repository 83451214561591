import React from 'react';
import { useTranslation } from 'react-i18next';
import { MuiTelInput } from 'mui-tel-input';
import { FormControl, FormHelperText } from '@mui/material';
import withVerify from '../withVerify/withVerify';
import { FieldRenderProps } from 'react-final-form';
import { NO_MESSAGE } from '../../constants';

const PhoneInput = (props: FieldRenderProps<Nullable<string | number>>) => {
  const { i18n, t } = useTranslation();
  const {
    label,
    disabled,
    input: { name, onChange, value, ...restInput },
    meta,
    showError,
    required,
  } = props;

  return (
    <FormControl fullWidth sx={{ mt: '16px', mb: '8px' }}>
      <MuiTelInput
        disabled={disabled}
        value={String(value)}
        name={name}
        error={meta.error && meta.touched}
        onChange={onChange}
        variant="outlined"
        label={label}
        langOfCountryName={i18n.language.split('-')[0]}
        defaultCountry="RU"
        {...restInput}
        required={required}
      />
      {showError && meta.error && meta.error !== NO_MESSAGE && meta.touched && (
        <FormHelperText
          sx={{ m: 0, position: 'absolute', bottom: '-17px' }}
          error
        >
          {t(meta.error)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withVerify(PhoneInput);
