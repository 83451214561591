import React from 'react';
import { SvgIcon } from '@mui/material';

const SettingsIcon = () => {
  return (
    <SvgIcon viewBox="0 0 18 18">
      <path d="M9.03423 7.80227c.75285 0 1.30037.61597 1.30037 1.30038 0 .68441-.61596 1.30035-1.30037 1.30035-.68441 0-1.30038-.61594-1.30038-1.30035 0-.68441.54753-1.30038 1.30038-1.30038zm0-.68441c-1.09506 0-1.98479.88973-1.98479 1.98479 0 1.09505.88973 1.98475 1.98479 1.98475 1.09507 0 1.98477-.8897 1.98477-1.98475 0-1.09506-.8897-1.98479-1.98477-1.98479z" />
      <path d="M10.2662.684411V3.01141l.479.13688c.4107.13688.7529.27376 1.0951.47909l.4791.27376.3422-.3422 1.3004-1.30038 1.7794 1.71102-1.3003 1.30038-.3422.34221.2737.47908c.2053.34221.3422.75286.4791 1.1635l.1369.47909h2.327v2.53236h-2.3954l-.1369.479c-.1369.4107-.2738.7529-.4791 1.0951l-.2738.4791.3422.3422 1.3004 1.3004-1.7795 1.7794-1.3003-1.3003-.2738-.2738-.4791.2053c-.3422.2053-.6844.3422-1.0266.4107l-.4791.1369v2.327H7.80228v-2.327l-.47909-.1369c-.41064-.1369-.75285-.2738-1.16349-.4791l-.47909-.2053-.34221.3422-1.30038 1.3003-1.77946-1.7794 1.23193-1.232.34221-.3422-.27376-.4791c-.20533-.4106-.41065-.8212-.54753-1.2319l-.06844-.4106H.684411V7.73384H2.94297l.13688-.47909c.20532-.41064.3422-.82129.54753-1.1635l.27376-.47908-.41065-.34221-1.23193-1.30038 1.77946-1.77947L5.3384 3.49049l.34221.34221.47909-.20532c.3422-.20533.68441-.34221 1.09505-.41065l.47909-.13688V.684411h2.53236zM10.9506 0H7.04943v2.53232c-.41065.13688-.82129.27376-1.23194.47909L4.24335 1.43726l-.20533-.20532-.20532.20532-2.327 2.327-.20532.20532.20532.20533 1.57415 1.57414c-.27377.41065-.47909.82129-.61597 1.23194H0V10.9506h2.46388c.13688.4791.3422.9581.54753 1.3688l-1.57415 1.5741-.20532.2054.20532.2053 2.327 2.327.20532.2053.20533-.2053 1.64258-1.5742c.41065.2054.82129.4107 1.30038.4791V18H11.019v-2.5323c.4107-.1369.7529-.2738 1.1635-.4791l1.5742 1.5741.2053.2054.2053-.2054 2.327-2.327.2053-.2053-.2053-.2053-1.5741-1.5741c.2053-.4107.4106-.8213.5475-1.232h2.6007V7.11787H15.5361c-.1369-.47909-.3422-.88973-.5475-1.30038l1.5741-1.57414.2054-.20533-.2054-.20532-2.327-2.327-.2053-.20532-.2053.20532-1.5741 1.57415c-.4107-.20533-.8213-.41065-1.232-.54753V0h-.0684z" />
    </SvgIcon>
  );
};

export default SettingsIcon;
