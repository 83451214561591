import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from '../../themes/theme';
import { useGetStatusQuery } from '../../features/auth/authApiSlice';

const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useGetStatusQuery();
  return (
    <ThemeProvider theme={getTheme(data?.settings.theme)}>
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
