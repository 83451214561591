import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Invite from './Invite/Invite';
import Terms from './Terms/Terms';
import Stats from './Stats/Stats';
import WithdrawBonuses from './WithdrawBonuses/WithdrawBonuses';
import s from './Bonuses.module.scss';

const BonusesIndividual = () => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <Typography variant="h4">{t('bonuses')}</Typography>
      <Invite />
      <Terms referral bodyKey="terms_of_sale_content" />
      <WithdrawBonuses />
      <Stats />
    </div>
  );
};

export default BonusesIndividual;
