import * as yup from 'yup';
import i18n from '../../locale/i18n';
import { t } from 'i18next';

const schema = () =>
  yup.object({
    firstname: yup
      .string()
      .matches(/^[a-zA-Z ]+$/g, t('letters_only_latin') || undefined)
      .required(i18n.t('required_field') || undefined),
    lastname: yup
      .string()
      .matches(/^[a-zA-Z ]+$/g, t('letters_only_latin') || undefined)
      .required(i18n.t('required_field') || undefined),
    email: yup
      .string()
      .email(i18n.t('email_not_valid') || ' ')
      .required(i18n.t('required_field') || undefined),
    password: yup
      .string()
      .min(6, i18n.t('password_valid_min', { value: 6 }) || undefined)
      .max(50, i18n.t('password_valid_max', { value: 50 }) || undefined)
      .matches(
        /^[0-9A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
        i18n.t('non_valid_symbols', { symbols: '0-9, A-Z, a-z' }) || undefined
      )
      .required(i18n.t('required_field') || undefined),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        i18n.t('passwords_do_not_match') || undefined
      )
      .required(i18n.t('required_field') || undefined),
    referralCode: yup
      .string()
      .min(6, i18n.t('valid_min', { value: 6 }) || undefined)
      .max(6, i18n.t('valid_max', { value: 6 }) || undefined)
      .matches(
        /(\w|\d)/,
        i18n.t('non_valid_symbols_ref', { symbols: '0-9, A-Z, a-z' }) ||
          undefined
      )
      .required(i18n.t('required_field') || undefined),
    termsOfUse: yup.bool().oneOf([true]).required(),
    captcha: yup
      .string()
      .nullable()
      .required(i18n.t('captcha_required') || undefined),
  });

export default schema;
