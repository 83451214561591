import i18n from '../locale/i18n';

const getTypeLabel = (type: string, genitive = false) => {
  switch (type) {
    case 'CPT':
    case 'XYO':
    case 'K2G':
    case 'K2G_C':
      return i18n.t(genitive ? 'tokens_genitive' : 'tokens');
    case 'PROOF':
    case 'XEM':
      return i18n.t(genitive ? 'mosaics_genitive' : 'mosaics');
    default:
      return i18n.t(genitive ? 'coins_genitive' : 'coins');
  }
};

export default getTypeLabel;
