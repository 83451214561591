import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import balanceReducer from '../features/balance/balanceSlice';
import { authApi } from './api/authApi';
import { robotApi } from './api/robotApi';
import { depositoryApi } from './api/depositoryApi';
import { bonusesApi } from './api/bonusesApi';
import { withdrawalApi } from './api/withdrawalApi';
import { cashInApi } from './api/cashInApi';
export const store = configureStore({
  reducer: {
    user: userReducer,
    balance: balanceReducer,
    [authApi.reducerPath]: authApi.reducer,
    [robotApi.reducerPath]: robotApi.reducer,
    [depositoryApi.reducerPath]: depositoryApi.reducer,
    [bonusesApi.reducerPath]: bonusesApi.reducer,
    [withdrawalApi.reducerPath]: withdrawalApi.reducer,
    [cashInApi.reducerPath]: cashInApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(robotApi.middleware)
      .concat(depositoryApi.middleware)
      .concat(bonusesApi.middleware)
      .concat(withdrawalApi.middleware)
      .concat(cashInApi.middleware),
});

export type AppStore = typeof store;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
