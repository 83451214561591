import React from 'react';
import { Field } from 'react-final-form';
import numbro from 'numbro';
import { useTranslation } from 'react-i18next';
import NumberInput from '../NumberInput/NumberInput';
import { useGetBalanceQuery } from '../../features/depository/depositoryApiSlice';

const CptAmountInput = ({ ...props }) => {
  const { t } = useTranslation();
  const { data } = useGetBalanceQuery();

  return (
    <Field
      name="amount"
      component={NumberInput}
      label={t('amount')}
      variant="outlined"
      helperText={`${numbro(data?.amount).format({
        thousandSeparated: true,
        trimMantissa: true,
        mantissa: 2,
      })} ${t('available_in_wallet')}`}
      endAdornment="CPT"
      style={{
        marginBottom: 16,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

export default CptAmountInput;
