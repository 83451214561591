import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import schema from './schema';
import validate from '../../../utilities/validate';
import Input from '../../../components/Input/Input';
import {
  useMfaEnabledMutation,
  useMfaDeleteMutation,
  useGetMfaEnabledQuery,
} from '../../../features/auth/authApiSlice';
import { useEnqueueSnackbarRemoteData } from '../../../utilities/snackbarEvents';

interface QrLinkDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  href: string;
}

const QrLinkDialog = ({
  openDialog,
  setOpenDialog,
  href,
}: QrLinkDialogProps) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<Nullable<string>>(null);
  const { data: dataMfaEnable } = useGetMfaEnabledQuery();
  const mfaEnabled = dataMfaEnable?.enabled;
  const [
    mfaEnabledPut,
    { isSuccess: isSuccessEn, isError: isErrorEn, error: errorEn },
  ] = useMfaEnabledMutation();
  const [
    mfaEnabledDelete,
    { isSuccess: isSuccessDel, isError: isErrorDel, error: errorDel },
  ] = useMfaDeleteMutation();

  const setModalState = (state: boolean) => () => {
    setOpenDialog(state);
  };

  useEffect(() => {
    if (href && !mfaEnabled) {
      QRCode.toDataURL(href, { errorCorrectionLevel: 'H' })
        .then((url) => setImage(url))
        .catch((err) => console.error(err));
    }
  }, [mfaEnabled, href]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onSubmit = (value: { code: string }) => {
    if (mfaEnabled) {
      mfaEnabledDelete(value);
    } else {
      mfaEnabledPut(value);
    }
  };

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  useEffect(() => {
    if (openDialog) {
      enqueueSnackbarRemoteData({
        isError: isErrorEn,
        error: errorEn,
        successMessage: isSuccessEn ? t('tfa_success') : '',
      });

      if (isSuccessEn) {
        setModalState(false)();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEn, isErrorEn, isSuccessEn]);

  useEffect(() => {
    if (openDialog) {
      enqueueSnackbarRemoteData({
        isError: isErrorDel,
        error: errorDel,
        successMessage: isSuccessDel ? t('tfa_success') : '',
      });

      if (isSuccessDel) {
        setModalState(false)();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDel, isErrorDel, isSuccessDel]);

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogContent>
        {mfaEnabled ? (
          <Typography>{t('confirm_delete_tfa')}</Typography>
        ) : (
          <>
            <Typography>{t('ga_scan')}</Typography>
            <Box
              sx={{
                textAlign: 'center',
                mt: '1rem',
                '& img': { maxWidth: '292px', width: '100%' },
              }}
            >
              {image != null && <img src={image} alt="QR" />}
            </Box>
          </>
        )}
        <Box sx={{ maxWidth: '292px', margin: '0 auto' }}>
          <Form
            onSubmit={onSubmit}
            validate={(values) => validate(values, schema)}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="code"
                  component={Input}
                  label={t('enter_verification_code')}
                  variant="outlined"
                />
                <Box
                  sx={{
                    mt: '1rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1rem',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    {t('send_transfer')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={setModalState(false)}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default QrLinkDialog;
