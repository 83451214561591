import React from 'react';
import { Typography, Button } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/FileDownload';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import BuyRobotFormDialog from '../../BuyRobotFormDialog/BuyRobotFormDialog';
import {
  Account,
  AccountStatuses,
  OwnershipValues,
} from '../../../../../features/robot/types';
import AddAccountFormDialog from '../../AddAccountFormDialog/AddAccountFormDialog';
import DeleteFormDialog from '../../DeleteFormDialog/DeleteFormDialog';
import AlertDialog from '../../../../../components/AlertDialog/AlertDialog';
import { useLazyDownloadRobotQuery } from '../../../../../features/robot/robotApiSlice';
import s from './AccountTableRow.module.scss';
import { DELETED } from '../../../../../constants';

interface AccountTableRowProps {
  account: Account;
}

const AccountTableRow = ({ account }: AccountTableRowProps) => {
  const { t } = useTranslation();
  const [downloadRobotById] = useLazyDownloadRobotQuery();

  const downloadRobot = () => {
    if (!account?.robot) return;
    downloadRobotById({ accountId: account.id });
  };

  const disabledEdit = account.status.value !== AccountStatuses.notVerified;

  return (
    <div className={s.main}>
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          className={s.columnName}
        >
          {t('account')}
        </Typography>
        <Typography variant="body1">{account.accountNumber}</Typography>
      </div>
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          className={s.columnName}
        >
          {t('status')}
        </Typography>
        <Typography variant="body1" className={s[account.status.value]}>
          {t(account.status.value)}
          {account.status.value === AccountStatuses.notVerified &&
            account?.comment && (
              <AlertDialog
                openControl={(openDialog) => (
                  <ReportGmailerrorredIcon
                    onClick={openDialog}
                    fontSize="medium"
                    sx={{ cursor: 'pointer' }}
                  />
                )}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: '.5rem' }}
                >
                  {t('account')} {account?.accountNumber}
                </Typography>
                <Typography variant="h5" sx={{ mb: '.5rem' }}>
                  {t('rejection_reason')}
                </Typography>
                <Typography>{account.comment}</Typography>
              </AlertDialog>
            )}
        </Typography>
      </div>
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          className={s.columnName}
        >
          {t('robot')}
        </Typography>
        <Typography variant="body1">
          {account.robot?.type.name && account.comment !== DELETED
            ? account.robot.type.name
            : '-'}
        </Typography>
      </div>
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          className={s.columnName}
        >
          {t('ownership')}
        </Typography>
        {account.comment !== DELETED && (
          <Typography variant="body1" component="div">
            {account.robot?.ownershipType?.value &&
              `${t(account.robot?.ownershipType?.value)} `}
            {account?.robot?.finishTime && (
              <>
                {t('till')}{' '}
                {format(
                  new Date(account?.robot?.finishTime as string),
                  'dd.MM.yyyy'
                )}
              </>
            )}
            {account.periodToPermanent > 0 && (
              <Typography variant="body2">
                {t('n_months_before', { count: account.periodToPermanent })}
              </Typography>
            )}
          </Typography>
        )}
      </div>
      <div className={s.lastColumn}>
        {account?.robot && account.comment !== DELETED ? (
          <>
            {account?.robot?.finishTime && (
              // Кнопка может быть разной (подписка или покупка) в зависимости от статуса
              <BuyRobotFormDialog
                account={account}
                newRobot={
                  account.robot?.ownershipType?.value === OwnershipValues.trial
                    ? true
                    : false
                }
                robotType={account.robot.type}
              />
            )}
            {account.robot.isDownloadable && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={downloadRobot}
              >
                <DownloadIcon className={s.icon} />
                <span className={s.onlyDesktop}>{t('download_robot')}</span>
              </Button>
            )}
          </>
        ) : account.status.value === AccountStatuses.verified ? (
          <BuyRobotFormDialog account={account} newRobot={true} />
        ) : (
          <>
            <AddAccountFormDialog
              accountId={account.id}
              disabled={disabledEdit}
              dialogOpeningComponent={(onClick: () => void) => (
                <Button
                  onClick={onClick}
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={disabledEdit}
                >
                  {t('edit')}
                </Button>
              )}
            />
            <DeleteFormDialog account={account} />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountTableRow;
