import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography, Button, SwipeableDrawer, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LeftMenu from '../../containers/LeftMenu/LeftMenu';
import LogoutIcon from '../../resources/icons/LogoutIcon';
import { HeaderProps } from '../../containers/Header/Header';
import GetTransferDialog from '../GetTransferDialog/GetTransferDialog';
import Balance from '../../containers/Balance/Balance';
import s from './MobileMenu.module.scss';

const MobileMenu = ({ loggedIn, userEmail, onLogout }: HeaderProps) => {
  const { t } = useTranslation();

  const [state, setState] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setState(open);
  };
  const handleDrawerOpen = () => {
    setState(true);
  };
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerOpen}
        className={s.icon}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className={s.content}>
            {!loggedIn && (
              <div className={s.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  component={Link}
                  to="/register"
                >
                  {t('registration')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  component={Link}
                  to="/login"
                >
                  {t('login')}
                </Button>
              </div>
            )}
            {loggedIn && (
              <>
                <LeftMenu />
                <Box sx={{ '& p': { mb: 1 } }}>
                  <Balance />
                  <GetTransferDialog />
                </Box>
                <div className={s.authInfo}>
                  <Typography variant="body1">{userEmail}</Typography>
                  <LogoutIcon
                    onClick={() => onLogout()}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
