import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { Link, RouterHistory, withRouter } from 'react-router-dom';
import FormBlock from '../../components/FormBlock/FormBlock';
import LoginForm from './LoginForm';
import { useGetStatusQuery } from '../../features/auth/authApiSlice';
import s from './Login.module.scss';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: status } = useGetStatusQuery();
  if (status?.loggedIn) {
    navigate('/');
    return null;
  }

  return (
    <>
      <FormBlock title={t('title_authorization')}>
        <LoginForm />
        <div className={s.buttons}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            component={Link}
            to="/recover"
          >
            {t('forgot_password')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            component={Link}
            to="/register"
          >
            {t('have_not_registered')}
          </Button>
        </div>
      </FormBlock>
    </>
  );
};

export default Login;
