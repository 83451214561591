import { t } from 'i18next';
import * as yup from 'yup';

const schema = () =>
  yup.object({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/g, t('letters_only_latin') || undefined)
      .required(),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/g, t('letters_only_latin') || undefined)
      .required(),
    phone: yup.string().required(),
  });

export default schema;
