import * as yup from 'yup';
import i18n from '../../../locale/i18n';

const schema = () =>
  yup.object({
    oldPassword: yup.string().required(),
    newPassword: yup
      .string()
      .min(6, i18n.t('password_valid_min', { value: 6 }))
      .max(50, i18n.t('password_valid_max', { value: 50 }))
      .matches(/^[0-9A-Za-z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/)
      .required(''),
    tfa_code: yup.string().required(),
  });

export default schema;
