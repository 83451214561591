import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import validate from '../../utilities/validate';
import Input from '../../components/Input/Input';
import schema from './schema';
import FormBlock from '../../components/FormBlock/FormBlock';
import { useEnqueueSnackbarRemoteData } from '../../utilities/snackbarEvents';
import { useNavigate } from 'react-router-dom';
import {
  useGetStatusQuery,
  useRestoringPasswordMutation,
} from '../../features/auth/authApiSlice';
import { RestoringPasswordRequest } from '../../features/auth/types';
import s from './RecoverPassword.module.scss';

const RecoverPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: status } = useGetStatusQuery();
  const [sendForm, { isSuccess, isLoading, isError, error }] =
    useRestoringPasswordMutation();

  if (status?.loggedIn) {
    navigate('/');
    return null;
  }

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  const onSubmit = (values: RestoringPasswordRequest) => {
    sendForm(values);
  };

  useEffect(() => {
    if (isLoading === false) {
      enqueueSnackbarRemoteData({
        isError,
        error,
        successMessage: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <FormBlock title={t('password_recovery')}>
      {isSuccess && (
        <div className={s.block}>
          <CheckIcon color="primary" className={s.icon} />
          <Typography variant="h5" align="center" gutterBottom>
            {t('recover_success')}
          </Typography>
        </div>
      )}
      {!isSuccess && (
        <Form
          onSubmit={onSubmit}
          validate={(values) => validate(values, schema)}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={s.form}>
              <Field
                name="email"
                component={Input}
                label="Email"
                margin="dense"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                style={{ marginTop: 16 }}
                fullWidth
              >
                {t('recover')}
              </Button>
            </form>
          )}
        </Form>
      )}
    </FormBlock>
  );
};
export default RecoverPassword;
