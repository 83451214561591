import * as yup from 'yup';
import { PurchaseType } from '../../../../features/robot/types';
import { t } from 'i18next';

const schema = () =>
  yup.object({
    robotTypeId: yup.string().required('This field is required'),
    ownershipTypeId: yup.string().required('This field is required'),
    period: yup
      .number()
      .typeError('')
      .min(1, t('greater_than_zero') || undefined)
      .when('ownershipTypeId', {
        is: (ownershipTypeId: string) => ownershipTypeId === PurchaseType.sub,
        then: (sc) => sc.required(t('required_field') || undefined),
      }),
  });

export default schema;
