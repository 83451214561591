import React from 'react';
import logoBlack from '../../resources/images/cash_flow_logo_black.png';
import logoWhite from '../../resources/images/cash_flow_logo_white.png';
import { DARK_THEME } from '../../themes/theme';
import { useTheme } from '@mui/material/styles';
import s from './Logo.module.scss';

const Logo = () => {
  const theme = useTheme();
  return (
    <img
      className={s.main}
      src={theme.palette.mode === DARK_THEME ? logoWhite : logoBlack}
      alt="logo"
    />
  );
};

export default Logo;
