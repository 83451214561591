import React from 'react';
import cx from 'classnames';
import { Routes, Route } from 'react-router-dom';
import Main from '../Main/Main';
import i18n from '../../locale/i18n';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Register from '../Register/Register';
import Login from '../Login/Login';
import RecoverPassword from '../RecoverPassword/RecoverPassword';
import NewPasswordForm from '../PasswordForm/PasswordForm';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import s from './App.module.scss';

const AppContainer = () => {
  return (
    <div className={cx(s.app, i18n.language.includes('vi') && s.vi)}>
      <Header />
      <div className={s.content}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recover" element={<RecoverPassword />} />
          <Route path="/verifyEmail" element={<VerifyEmail />} />
          <Route path="/passwordRestore" element={<NewPasswordForm />} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default AppContainer;
