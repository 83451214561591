export type Currency = 'USDT' | 'USD';

export interface HistoryRequest {
  From: string;
  To: string;
  Currency: Currency;
  Statuses?: string;
  PageIndex: number;
  PageSize: number;
}

export interface WithdrawRequest {
  amount: number;
  currency: Currency;
  address: string;
  message: Nullable<string>;
  code2Fa: string;
}

export interface EstimationFeeRequest {
  amount: number;
  currency: Currency;
  address: string;
  message: Nullable<string>;
}

export interface EstimationFee {
  feeAmount: number;
  currency: Currency;
}

type WithdrawStatus = 'Error';
export interface Withdraw {
  id: number;
  amount: number;
  currency: Currency;
  uuid: string;
  status: {
    id: number;
    name: WithdrawStatus;
  };
  transactionId: 10;
  error: string;
}

export enum HistoryItemStatusName {
  'PENDING' = 'Pending',
  'WAITING_CONFIRMATIONS' = 'WaitingConfirmations',
  'CONVERTING' = 'Converting',
  'WAITING' = 'Waiting',
  'PROCESSING' = 'Processing',
  'SUCCESS' = 'Success',
  'DONE' = 'Done',
  'COMPLETED' = 'Completed',
  'ERROR' = 'Error',
  'FAILED' = 'Failed',
}

export interface HistoryItemStatus {
  id: number;
  name: HistoryItemStatusName;
}

export interface HistoryItem {
  amount: number;
  fee: number;
  currency: Currency;
  timestamp: string;
  status: HistoryItemStatus;
  message: Nullable<string>;
  txHash: string;
  address: string;
}

export interface PageInfo {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface History {
  pageInfo: PageInfo;
  items: HistoryItem[];
}
