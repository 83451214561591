import authApi from '../../app/api/authApi';
import { dispatchSetUserTokens } from '../../features/user/userSlice';
import {
  Registration,
  RegistrationRequest,
  Login,
  LoginRequest,
  Settings,
  VerifyEmailRequest,
  RestoringPasswordRequest,
  SetPasswordRequest,
  Mfa,
  User,
  Invitation,
  EditUserDataRequest,
  ChangePasswordRequest,
} from './types';

export const authApiSlice = authApi.injectEndpoints({
  endpoints: (build) => ({
    registration: build.query<Registration, RegistrationRequest>({
      query: (body) => ({
        url: '/api/auth/authorizations/registration',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: Registration }) => {
        return response.data;
      },
    }),
    login: build.mutation<Login, LoginRequest>({
      query: (body) => ({
        url: '/api/auth/authorizations/login',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: Login }) => {
        return response.data;
      },
      invalidatesTags: (data) => {
        if (data?.refreshToken) {
          dispatchSetUserTokens(data);
          return ['Status'];
        }
        return [];
      },
    }),
    update: build.mutation<void, Settings>({
      query: (body) => ({
        url: '/api/auth/settings/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Status'],
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        dispatch(
          authApiSlice.util.updateQueryData('getStatus', undefined, (draft) => {
            Object.assign(draft, { settings: arg });
          })
        );
      },
    }),
    verifyEmail: build.query<void, VerifyEmailRequest>({
      query: (body) => ({
        url: '/api/auth/authorizations/verifyemail',
        method: 'POST',
        body,
      }),
    }),
    restoringPassword: build.mutation<Registration, RestoringPasswordRequest>({
      query: (body) => ({
        url: '/api/auth/authorizations/restoringpassword',
        method: 'POST',
        body,
      }),
    }),
    setPassword: build.mutation<any, SetPasswordRequest>({
      query: ({ id, token, password }) => ({
        url: `/api/auth/authorizations/restoringpassword/${id}`,
        method: 'PUT',
        body: { password },
        params: { token },
      }),
    }),
    changePassword: build.mutation<any, ChangePasswordRequest>({
      query: (body) => ({
        url: '/api/auth/authorizations/password',
        method: 'PUT',
        body,
      }),
    }),
    getMfaEnabled: build.query<Mfa, void>({
      query: () => ({
        url: '/api/auth/authorizations/mfa/enabled',
        method: 'GET',
      }),
      transformResponse: (response: { data: Mfa }) => {
        return response.data;
      },
      providesTags: ['Mfa'],
    }),
    /**
     * Returns the link for the qr code
     */
    mfaEnabledPre: build.mutation<string, void>({
      query: () => ({
        url: `/api/auth/authorizations/mfa`,
        method: 'PUT',
      }),
      transformResponse: (response: { data: string }) => {
        return response.data;
      },
    }),
    mfaEnabled: build.mutation<void, { code: string }>({
      query: (body) => ({
        url: `/api/auth/authorizations/mfa/enabled`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Mfa'],
    }),
    mfaDelete: build.mutation<void, { code: string }>({
      query: (body) => ({
        url: `/api/auth/authorizations/mfa`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Mfa'],
    }),
    getUserInfo: build.query<User, void>({
      query: () => ({
        url: `/api/auth/authorizations/my-user-info`,
        method: 'GET',
      }),
      transformResponse: (response: { data: User }) => {
        return response.data;
      },
      providesTags: ['User'],
    }),
    setUserInfo: build.mutation<void, EditUserDataRequest>({
      query: (body) => ({
        url: `/api/auth/authorizations/my-user-info`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    getReferralCode: build.query<string, void>({
      query: () => ({
        url: `/api/auth/authorizations/referral-code`,
        method: 'GET',
      }),
      transformResponse: (response: { referralCode: 'string' }) => {
        return response.referralCode;
      },
    }),
    sendInvitations: build.mutation<void, Invitation>({
      query: (body) => ({
        url: `/api/auth/authorizations/invites`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetStatusQuery,
  useLazyRegistrationQuery,
  useLoginMutation,
  useLogoutMutation,
  useUpdateMutation,
  useVerifyEmailQuery,
  useRestoringPasswordMutation,
  useSetPasswordMutation,
  useChangePasswordMutation,
  useGetMfaEnabledQuery,
  useMfaEnabledMutation,
  useMfaEnabledPreMutation,
  useMfaDeleteMutation,
  useGetUserInfoQuery,
  useGetReferralCodeQuery,
  useSetUserInfoMutation,
  useSendInvitationsMutation,
} = authApiSlice;
