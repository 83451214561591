import React from 'react';
import { Typography, Paper, CircularProgress, Box } from '@mui/material';
import copy from 'copy-to-clipboard';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useGetReferralCodeQuery } from '../../features/auth/authApiSlice';
import s from './ReferralCode.module.scss';

const ReferralCode = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: referralCode, isLoading } = useGetReferralCodeQuery();

  const copyRefLink = () => {
    if (typeof referralCode === 'string') {
      copy(referralCode as string);
      enqueueSnackbar(t('successfully_copied'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('unknown_error'), { variant: 'error' });
    }
  };

  return (
    <Paper elevation={0} className={s.referral}>
      <Box className={s.link}>
        <Typography variant="body1" color="textSecondary">
          {t('your_ref_code')}
        </Typography>
        {isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <div className={s.linkContent}>
            <Typography variant="h6">{referralCode}</Typography>
            <CopyIcon color="primary" onClick={copyRefLink} />
          </div>
        )}
      </Box>
    </Paper>
  );
};

export default ReferralCode;
