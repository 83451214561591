import React, { useState, useEffect } from 'react';
import { Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

interface TabMenuProps {
  children?: React.ReactNode;
  defaultTab: string;
}

const TabMenu = ({ children, defaultTab, ...other }: TabMenuProps) => {
  const location = useLocation();
  const [tab, setTab] = useState(defaultTab);
  const navigate = useNavigate();

  const onTabChange = (
    event: React.SyntheticEvent,
    value: TabMenuProps['defaultTab']
  ) => {
    setTab(value);
    navigate(value);
  };

  useEffect(() => {
    // Effect with timeout to handle MUI bug
    setTimeout(() => setTab(location.pathname), 1);
  });

  return (
    <Tabs
      value={tab}
      onChange={onTabChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      {children}
    </Tabs>
  );
};

export default TabMenu;
