import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import validate from '../../utilities/validate';
import Input from '../../components/Input/Input';
import schema from './schema';
import CheckBox from '../../components/CheckBox/CheckBox';
import FormBlock from '../../components/FormBlock/FormBlock';
import FormSuccess from '../../components/FormSuccess/FormSuccess';
import { useEnqueueSnackbarRemoteData } from '../../utilities/snackbarEvents';
import {
  useGetStatusQuery,
  useLazyRegistrationQuery,
} from '../../features/auth/authApiSlice';
import { RegistrationRequest } from '../../features/auth/types';
import FieldPassword from '../../components/FieldPassword/FieldPassword';
import trim from '../../utilities/trim/trim';
import s from './Register.module.scss';

const pdfUrl = `/documents/termofuse/Terms_of_Use.pdf`;

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sendForm, { isError, error, isLoading, isFetching, isSuccess }] =
    useLazyRegistrationQuery();
  const { data: status } = useGetStatusQuery();

  const [searchParams] = useSearchParams();
  const ref = searchParams.get('refCode');

  // const captchaRef = useRef(null);
  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  // useEffect(() => props.onReset, []);

  useEffect(() => {
    if (isLoading === false) {
      enqueueSnackbarRemoteData({
        isError,
        error,
        successMessage: isSuccess ? 'Success' : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = (values: RegistrationRequest) => {
    sendForm(values);
    // captchaRef.current.reset();
  };

  if (status?.loggedIn) {
    navigate('/');
  }

  return (
    <>
      <FormBlock title={t('registration')}>
        {isSuccess && (
          <FormSuccess
            title={t('register_success')}
            subTitle={t('register_success_sub')}
          />
        )}
        {!isSuccess && (
          <Form
            onSubmit={onSubmit}
            validate={(values) => validate(values, schema())}
            initialValues={{ referralCode: ref }}
          >
            {({ handleSubmit, valid }) => (
              <form onSubmit={handleSubmit} className={s.form}>
                <Field
                  name="firstname"
                  component={Input}
                  label={t('first_name')}
                  variant="outlined"
                  autoFocus
                  showError
                  format={(value) => trim(value)}
                  formatOnBlur
                />
                <Field
                  name="lastname"
                  component={Input}
                  label={t('last_name')}
                  variant="outlined"
                  showError
                  format={(value) => trim(value)}
                  formatOnBlur
                />
                <Field
                  name="email"
                  component={Input}
                  label="Email"
                  variant="outlined"
                  showError
                  formatOnBlur
                  format={(value) => trim(value)}
                />
                <FieldPassword
                  name="password"
                  component={Input}
                  type="password"
                  label={t('password')}
                  variant="outlined"
                  showError
                  formatOnBlur
                  format={(value) => trim(value)}
                />
                <FieldPassword
                  name="confirmPassword"
                  component={Input}
                  type="password"
                  label={t('confirm_password')}
                  variant="outlined"
                  showError
                  formatOnBlur
                  format={(value) => trim(value)}
                />
                <Field
                  name="referralCode"
                  component={Input}
                  label={t('referrer_code')}
                  variant="outlined"
                  disabled={ref}
                  showError
                />
                {/* <Field
                  name="captcha"
                  component={ReCAPTCHA}
                  forwardedRef={captchaRef}
                /> */}
                <Field
                  name="captcha"
                  component="input"
                  type="hidden"
                  defaultValue="***"
                />
                <Field
                  type="checkbox"
                  name="termsOfUse"
                  component={CheckBox}
                  label={
                    <Typography variant="body2">
                      {t('i_agree')}{' '}
                      <Link
                        href={pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('terms_and_conditions')}
                      </Link>
                    </Typography>
                  }
                  color="primary"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={s.formButton}
                  disabled={isFetching || !valid}
                  fullWidth
                >
                  {t('register')}
                </Button>
              </form>
            )}
          </Form>
        )}
      </FormBlock>
    </>
  );
};

export default Register;
