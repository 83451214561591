import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import schema from './schema';
import validate from '../../utilities/validate';
import Input from '../../components/Input/Input';
import { useQuery } from '../../hooks/router/useQuery';
import { useSetPasswordMutation } from '../../features/auth/authApiSlice';
import FormBlock from '../../components/FormBlock/FormBlock';
import { useEnqueueSnackbarRemoteData } from '../../utilities/snackbarEvents';
import FieldPassword from '../../components/FieldPassword/FieldPassword';
import s from './PasswordForm.module.scss';

const PasswordForm = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const id = query.get('id') || '';
  const token = (query.get('token') || '').replace(/ /g, '+');

  const [sendForm, { isSuccess, isLoading, isError, error }] =
    useSetPasswordMutation();

  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();

  useEffect(() => {
    if (isLoading === false) {
      enqueueSnackbarRemoteData({
        isError,
        error,
        successMessage: isSuccess ? t('success_changes_saved') : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = (values: { password: string }) => {
    sendForm({ ...values, id, token });
  };

  return (
    <FormBlock title={t('new_password')}>
      {isSuccess && (
        <div className={s.block}>
          <CheckIcon color="primary" className={s.icon} />
          <Link to="/login">
            <Typography variant="h5" align="center" gutterBottom>
              {t('login')}
            </Typography>
          </Link>
        </div>
      )}
      {!isSuccess && (
        <Form
          onSubmit={onSubmit}
          validate={(values) => validate(values, schema())}
        >
          {({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <FieldPassword
                name="password"
                component={Input}
                type="password"
                label={t('new_password')}
                variant="outlined"
                showError
              />
              <Button
                sx={{ mt: '2rem' }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || !valid}
                fullWidth
              >
                {t('send')}
              </Button>
            </form>
          )}
        </Form>
      )}
    </FormBlock>
  );
};

export default PasswordForm;
