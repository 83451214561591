import React from 'react';
import { SvgIcon } from '@mui/material';

const BonusesIcon = () => {
  return (
    <SvgIcon viewBox="0 0 20 20">
      <path d="M11.9259 3.4957V1.61165L13.037.933386c.2963-.226086.6667-.226086 1.0371 0 .2963.150724.5185.527534.5185.904344V3.4957h.7407V1.83773c0-.67826-.2963-1.205792-.8889-1.582603-.5925-.3014481-1.1851-.3014481-1.7777 0l-1.1852.753623H8.59259L7.40741.255127c-.51852-.3014481-1.18519-.37681-1.77778 0-.59259.301449-.96296.904343-.96296 1.582603V3.4957h.74074V1.83773c0-.37681.22222-.67826.51852-.904344.29629-.150724.66666-.150724 1.03703 0l1.11111.678264V3.4957H0V20h20V3.4957h-8.0741zm.1482.75362V19.2464H7.85185V4.24932h4.22225zM8.81481 1.76237h2.37039V3.4957H8.81481V1.76237zM.740741 4.24932H7.18518V19.2464H.740741V4.24932zM19.2593 19.2464h-6.4445V4.24932h6.4445V19.2464z" />
    </SvgIcon>
  );
};

export default BonusesIcon;
