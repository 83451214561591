const getDateRange = (month: number): { startDate: Date; endDate: Date } => {
  const startDate = new Date();
  const endDate = new Date();
  let newMonth = startDate.getMonth() - month;
  if (newMonth < 0) {
    newMonth += 12;
    startDate.setFullYear(startDate.getFullYear() - 1);
  }
  startDate.setMonth(newMonth);
  return { startDate, endDate };
};

export default getDateRange;
