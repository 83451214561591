import React, { useState } from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';

interface AlertDialogProps {
  openControl: (openDialog: () => void) => React.ReactNode;
  children: React.ReactNode;
}

const AlertDialog = ({ openControl, children }: AlertDialogProps) => {
  const [isOpen, setOpen] = useState(false);

  const setModalState = (state: boolean) => () => setOpen(state);

  return (
    <>
      {openControl(setModalState(true))}
      <Dialog open={isOpen} onClose={setModalState(false)}>
        <DialogContent>
          {children}
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            onClick={setModalState(false)}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AlertDialog;
