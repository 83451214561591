import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Typography,
  DialogContent,
  Button,
  Dialog,
  MenuItem,
  Box,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import schema from './schema';
import Select from '../../../../components/Select/Select';
import validate from '../../../../utilities/validate';
import Input from '../../../../components/Input/Input';
import s from './BuyRobotFormDialog.module.scss';
import {
  useBuyRobotMutation,
  useGetPriceInfoQuery,
  useGetRobotsTypesQuery,
  useRenewRobotSubscriptionMutation,
} from '../../../../features/robot/robotApiSlice';
import { useEnqueueSnackbarRemoteData } from '../../../../utilities/snackbarEvents';
import {
  Account,
  OwnershipValues,
  PurchaseType,
  RobotPurchaseBody,
  RobotType,
} from '../../../../features/robot/types';
import _ from 'lodash';
import useUserBalance from '../../../../hooks/useUserBalance/useUserBalance';

type RobotDialogProps = {
  account: Account;
  newRobot?: boolean;
  robotType?: RobotType | null;
};

const PURCHASE_TYPES: PurchaseType[] = Object.values(PurchaseType);

const BuyRobotFormDialog = ({
  account,
  newRobot = true,
  robotType = null,
}: RobotDialogProps) => {
  const { enqueueSnackbarRemoteData } = useEnqueueSnackbarRemoteData();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [period, setPeriod] = useState<number | undefined>();
  const { data: priceInfo, isFetching } = useGetPriceInfoQuery(
    { id: account?.id, period },
    { skip: !isOpen }
  );

  const { data: robotTypes, isSuccess: robotsTypesLoaded } =
    useGetRobotsTypesQuery();
  const [buyRobot, { isSuccess: purchaseSuccess, isError, error }] =
    useBuyRobotMutation();
  const [
    renewSubscription,
    { isSuccess: renewSubSuccess, isError: isSubError, error: subError },
  ] = useRenewRobotSubscriptionMutation();
  const userBalance = useUserBalance();
  const setModalState = (state: boolean) => () => setOpen(state);

  const onSubmit = async ({
    period,
    robotTypeId,
    ownershipTypeId,
  }: RobotPurchaseBody) => {
    if (newRobot) {
      await buyRobot({
        id: account.id,
        period:
          ownershipTypeId === PurchaseType.per
            ? priceInfo?.period
            : Number(period),
        robotTypeId,
        ownershipTypeId,
      });
    } else {
      await renewSubscription({ id: account.id, period: Number(period) });
    }
    setOpen(false);
  };

  useEffect(() => {
    enqueueSnackbarRemoteData({
      isError,
      error,
      successMessage: purchaseSuccess ? t('transaction_success') : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError, purchaseSuccess]);

  useEffect(() => {
    enqueueSnackbarRemoteData({
      isError,
      error,
      successMessage: renewSubSuccess ? t('transaction_success') : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subError, isSubError, renewSubSuccess]);

  const calculatePrice = useCallback(
    ({ ownershipTypeId }: RobotPurchaseBody) => {
      let result: Nullable<number> = undefined;

      if (!priceInfo) return result;

      const { subscriptionPrice, permanentPrice } = priceInfo;

      if (ownershipTypeId === PurchaseType.sub && subscriptionPrice) {
        result = subscriptionPrice;
      } else if (ownershipTypeId === PurchaseType.per && permanentPrice) {
        result = permanentPrice;
      }

      return result;
    },
    [priceInfo]
  );

  const handleInputPeriod = (event: ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    setPeriod(value > 0 ? value : undefined);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={setModalState(true)}
      >
        {newRobot
          ? account.robot?.ownershipType?.value === OwnershipValues.trial
            ? t('buy_robot')
            : t('buy_new_robot')
          : t('renew_subscription')}
      </Button>
      <Dialog
        open={isOpen}
        onClose={setModalState(false)}
        classes={{ paper: s.dialogPaper }}
        PaperProps={{ elevation: 0 }}
      >
        <DialogContent
          sx={{
            minWidth: '400px',
            maxWidth: '450px',
          }}
        >
          <Box>
            <div className={s.upper_row}>
              <Typography variant="body2" color="textSecondary">
                {t('account')} {account.accountNumber}
              </Typography>
              <CloseIcon
                sx={{ cursor: 'pointer' }}
                onClick={setModalState(false)}
                fontSize="small"
              />
            </div>

            <Typography className={s.title} variant="h5">
              {newRobot ? t('buy_robot') : t('renew_subscription')}
            </Typography>
            {newRobot && (
              <Typography my={'0.5rem'} variant="body1">
                {t('buy_new_robot_subtitle')}
              </Typography>
            )}
          </Box>
          <Form
            sx={{ mt: '20px' }}
            onSubmit={onSubmit}
            validate={(values) => validate(values, schema())}
          >
            {({ handleSubmit, valid, values }) => {
              const price = calculatePrice(values);
              const isInsufficientFunds =
                !userBalance || !!(price && userBalance < price);
              return (
                <form className={s.form}>
                  <Field
                    name="robotTypeId"
                    component={Select}
                    label={`${t('robot_type')} *`}
                    variant="outlined"
                    hideError
                    required
                    disabled={robotType !== null} // если робот уже куплен, поле заблокировано - менять при продлении нельзя
                    initialValue={robotType?.id}
                    className={s.selectField}
                  >
                    {robotsTypesLoaded &&
                      robotTypes?.map(({ id, name }) => (
                        <MenuItem value={id} key={id}>
                          {_.capitalize(name)}
                        </MenuItem>
                      ))}
                  </Field>
                  <Field
                    name="ownershipTypeId"
                    component={Select}
                    label={`${t('purchase_type')} *`}
                    variant="outlined"
                    hideError
                    required
                    className={s.selectField}
                    disabled={!newRobot}
                    initialValue={
                      account?.robot?.ownershipType?.value?.toLowerCase() || ''
                    }
                  >
                    {PURCHASE_TYPES.map((val) => (
                      <MenuItem value={val} key={val}>
                        {_.capitalize(`${t(val)}`)}
                      </MenuItem>
                    ))}
                  </Field>
                  {values.ownershipTypeId === PurchaseType.sub && priceInfo && (
                    <Field
                      format={(value: string) => {
                        return !value?.match(/[\d+]/) ? null : value;
                      }}
                      showError
                      validate={(val: string) => {
                        if (Number(val) > priceInfo?.period)
                          return t('month_exceed_error', {
                            count: priceInfo?.period,
                          });
                        return '';
                      }}
                      inputProps={{
                        maxLength: 2,
                        onChange: handleInputPeriod,
                      }}
                      name="period"
                      component={Input}
                      label={t('period_months')}
                      variant="outlined"
                      required
                      autoFocus
                    />
                  )}
                  {isInsufficientFunds && valid && (
                    <Typography
                      color={(theme) => theme.palette.error.main}
                      sx={{ position: 'absolute', mt: '-3px' }}
                    >
                      {t('insufficient_funds')}
                    </Typography>
                  )}
                  <div className={s.control}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ marginTop: 24 }}
                      type="button"
                      disabled={
                        !valid ||
                        isInsufficientFunds ||
                        !priceInfo ||
                        isFetching
                      }
                      onClick={handleSubmit}
                    >
                      {isFetching ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          {t('buy')} {price ? `${price} USD` : ''}
                        </>
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      style={{ marginTop: 24 }}
                      onClick={setModalState(false)}
                    >
                      {t('cancel')}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BuyRobotFormDialog;
