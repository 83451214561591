import * as yup from 'yup';

const schema = yup.object({
  amount: yup
    .number()
    .test({
      name: 'max-price',
      test: function test(value) {
        return this.options.context.maxPrice >= value;
      },
    })
    .required(),
  address: yup
    .string()
    .when(['$currency'], ($currency, s) => {
      return s.min(42).max(42);
    })
    .required(),
  message: yup.string().max(1023),
  code2Fa: yup.string().required(),
});

export default schema;
