import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  addYears,
} from 'date-fns';
import * as locales from 'react-date-range/src/locale';
import i18n from '../locale/i18n';

const defineds = (locale) => {
  if (locale === 'en') {
    locale = 'enUS';
  }

  const { weekStartsOn } = locales[locale].options;

  return {
    startOfWeek: startOfWeek(new Date(), { weekStartsOn }),
    endOfWeek: new Date(),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn }),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn }),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: new Date(),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const getStaticRanges = () => {
  const definedsLocale = defineds(i18n.language.split('-')[0]);
  return createStaticRanges([
    {
      label: i18n.t('today'),
      range: () => ({
        startDate: definedsLocale.startOfToday,
        endDate: definedsLocale.endOfToday,
      }),
    },
    {
      label: i18n.t('yesterday'),
      range: () => ({
        startDate: definedsLocale.startOfYesterday,
        endDate: definedsLocale.endOfYesterday,
      }),
    },

    {
      label: i18n.t('week'),
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -7)),
        endDate: definedsLocale.endOfToday,
      }),
    },
    {
      label: i18n.t('month'),
      range: () => ({
        startDate: startOfDay(addMonths(new Date(), -1)),
        endDate: definedsLocale.endOfToday,
      }),
    },
    {
      label: i18n.t('year'),
      range: () => ({
        startDate: startOfDay(addYears(new Date(), -1)),
        endDate: definedsLocale.endOfToday,
      }),
    },
  ]);
};
