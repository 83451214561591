import { useEffect, useState } from 'react';
import {
  FormRenderProps,
  FormSpy,
  FormSpyProps,
  FormSpyRenderProps,
} from 'react-final-form';
import diff from 'object-diff';

type Params = {
  debounce?: number;
  save?: (values: FormRenderProps['values']) => void;
  validFields?: ('address' | 'amount')[];
};

type AutoSaveProps = FormSpyRenderProps & Params;

type AutoSaveFSProps = FormSpyProps & Params;

const AutoSave = (props: AutoSaveProps) => {
  const { debounce } = props;
  const [values, setValues] = useState(props.values);
  const [timeout, setUpTimeout] = useState<NodeJS.Timeout | null>(null);

  const submit = async () => {
    const { values: formValues, valid, validFields, save } = props;
    if (
      valid ||
      validFields?.reduce(
        (a, i) => a && !!values[i] && values[i].length > 0,
        true
      )
    ) {
      // This diff step is totally optional
      const difference = diff(values, formValues);
      if (Object.keys(difference).length) {
        // values have changed
        setValues(formValues);
        save && save(formValues);
      }
    }
  };

  useEffect(() => {
    if (debounce) {
      if (timeout) {
        clearTimeout(timeout);
      }
      setUpTimeout(setTimeout(submit, debounce));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values, debounce]);

  return null;
};

const AutoSaveFS = (props: AutoSaveFSProps) => (
  <FormSpy
    {...props}
    subscription={{ values: true, valid: true }}
    component={AutoSave}
  />
);

export default AutoSaveFS;
