import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import HeaderDesktop from '../../components/HeaderDesktop/HeaderDesktop';
import HeaderMobile from '../../components/HeaderMoblile/HeaderMobile';
import {
  useGetStatusQuery,
  useLogoutMutation,
} from '../../features/auth/authApiSlice';
import s from './Header.module.scss';

export interface HeaderProps {
  showWarning: boolean;
  loggedIn: Nullable<boolean>;
  userEmail: Nullable<string>;
  onLogout: () => void;
}

const Header = () => {
  const { t } = useTranslation();
  const { data: status } = useGetStatusQuery();
  const [onLogout] = useLogoutMutation();

  // TODO
  const showWarning = false;

  const headerProps: HeaderProps = {
    showWarning: showWarning,
    loggedIn: status?.loggedIn,
    userEmail: status?.email,
    onLogout: onLogout,
  };

  return (
    <>
      {showWarning && (
        <div className={s.warning}>
          <Typography color="inherit" variant="body1">
            {t('2fa_warning')}
          </Typography>
        </div>
      )}
      <HeaderDesktop {...headerProps} />
      <HeaderMobile {...headerProps} />
    </>
  );
};

export default Header;
