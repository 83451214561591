import React from 'react';
import './config.js';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import './locale/i18n';
import './index.css';
import './yupConfig';

const container = document.getElementById('root')!;
const root = createRoot(container);

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line import/no-unresolved
//   const { worker } = require('./mocks/browser.ts');
//   worker.start();
// }

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
