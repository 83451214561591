/**
 * file download method
 * @param {response} Response
 * @param {string} [fileName] - file name
 */
const downloadDocument = async (response: Response, fileName = '') => {
  let newFileName = fileName;

  if (!newFileName) {
    newFileName = 'downloaded_file';
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        newFileName = matches[1].replace(/['"]/g, '');
      }
    }
  }

  const data = await response.blob();
  const hiddenElement = document.createElement('a');
  const url = window.URL || window.webkitURL;
  const blob = url.createObjectURL(data);
  hiddenElement.href = blob;
  hiddenElement.target = '_blank';
  hiddenElement.download = newFileName;
  hiddenElement.click();
  hiddenElement.remove();
  return { data: null };
};

export default downloadDocument;
