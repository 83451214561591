import React from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReferralItem from '../ReferralItem/ReferralItem';
import BonusesFilterForm from '../BonusesFilterForm';
import sItem from '../ReferralItem/ReferralItem.module.scss';
import { useLazyGetBalanceQuery } from '../../../features/bonuses/bonusesApiSlice';
import s from './Stats.module.scss';
import { useGetStatusQuery } from '../../../features/auth/authApiSlice';

export const TICKER = 'USD';

const Stats = () => {
  const { t } = useTranslation();
  const [getTree, { data, isFetching }] = useLazyGetBalanceQuery();
  const { data: statusInfo } = useGetStatusQuery();

  return (
    <div className={s.stats}>
      <div className={s.total}>
        <div className={s.filter}>
          <BonusesFilterForm getTree={getTree} />
          {isFetching && <CircularProgress />}
        </div>
        <Typography
          sx={{
            gridColumnStart: statusInfo?.isLeader ? 2 : 3, // смещаем заголовок вправо если бонусы лидера скрыты
          }}
          className={s.second}
          variant="h5"
        >
          {t('bonuses')}
        </Typography>
        {statusInfo?.isLeader && (
          <Typography className={s.third} variant="h5">
            {t('leaders_bonuses')}
          </Typography>
        )}
      </div>
      {data && statusInfo && (
        <>
          <div className={s.total}>
            <Typography variant="h5">{t('total')}</Typography>
            <Box
              sx={{
                gridColumnStart: statusInfo?.isLeader ? 2 : 3, // смещаем баланс вправо если бонусы лидера скрыты
              }}
              className={s.col}
            >
              <Typography variant="h5">
                {data.totalBonuses} {TICKER}
              </Typography>
            </Box>
            {statusInfo?.isLeader && (
              <div className={s.col}>
                <Typography variant="h5">
                  {data.totalLeaderBonuses} {TICKER}
                </Typography>
              </div>
            )}
          </div>
          {data?.referralBonuses.length > 0 && (
            <div className={s.referrals}>
              <div
                className={[
                  sItem.container,
                  !statusInfo?.isLeader ? sItem.two_columns : '',
                ].join(' ')}
              >
                {data?.referralBonuses.map((i, index) => (
                  <ReferralItem
                    displayLeaderCol={statusInfo.isLeader}
                    {...i}
                    key={`${i.firstName}_${i.lastName}_${index}`}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Stats;
